import { RelatedQuestion } from "./relatedQuestion";

export interface ClientAttachment {
    type: string;
    url: string;
    name: string;
    extension: string;
}

export class Attachment {
    type: string;
    url: string;
    name: string;

    constructor(type: string, url: string, name?: string) {
        this.type = type;
        this.url = url;
        this.name = name || this.getName();
    }

    private getName(): string {
        const segments = this.url.split('/');
        return segments.pop() || '';
    }
}

export class UserAttachment {
    url: string;

    constructor(url: string) {
        this.url = url;
    }
}

export interface WebSocketMessage {
    id?: string;
    type: string;
    query: string;
    conversationId: string | null;
    questionId: string | null;
    formattedContent: WebSocketFormatedContent[];
    language: string;
    token: string | null;
    device: string;
    documentAnalysisId: string | null;
    mode?: string;
    audioAnswer?: string;
    attachments?: Attachment[];
    user_attachments?: UserAttachment[];
    questionList?: RelatedQuestion[];
}

export interface WebSocketFeedback {
    type: string;
    feedback_id: string; //message_id
    feedback_status: boolean; //like or dislike
    feedback_message: string; // text of feedback
    feedback_reason: string; // one of the selected reasons
}

export interface WebSocketFormatedContent {
    type: string;
    value: any;
}
