import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-instagram',
    template: `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fi_87390" clip-path="url(#clip0_1447_1469)">
                <g id="Group">
                    <path id="Vector" d="M23.1688 -3.05176e-05H8.8306C3.96141 -3.05176e-05 0 3.96157 0 8.83076V23.169C0 28.0384 3.96141 31.9998 8.8306 31.9998H23.1688C28.0384 31.9998 31.9998 28.0382 31.9998 23.169V8.83076C32 3.96157 28.0384 -3.05176e-05 23.1688 -3.05176e-05ZM29.1608 23.169C29.1608 26.4728 26.4729 29.1606 23.169 29.1606H8.8306C5.52693 29.1608 2.83918 26.4728 2.83918 23.169V8.83076C2.83918 5.52709 5.52693 2.83915 8.8306 2.83915H23.1688C26.4727 2.83915 29.1606 5.52709 29.1606 8.83076V23.169H29.1608Z" [attr.fill]="stroke"/>
                    <path id="Vector_2" d="M15.9994 7.75471C11.4528 7.75471 7.75391 11.4536 7.75391 16.0003C7.75391 20.5467 11.4528 24.2454 15.9994 24.2454C20.5461 24.2454 24.245 20.5467 24.245 16.0003C24.245 11.4536 20.5461 7.75471 15.9994 7.75471ZM15.9994 21.406C13.0185 21.406 10.5931 18.981 10.5931 16.0001C10.5931 13.0189 13.0183 10.5937 15.9994 10.5937C18.9806 10.5937 21.4058 13.0189 21.4058 16.0001C21.4058 18.981 18.9804 21.406 15.9994 21.406Z" [attr.fill]="stroke"/>
                    <path id="Vector_3" d="M24.5909 5.34728C24.0438 5.34728 23.5065 5.56873 23.1202 5.95675C22.7319 6.34288 22.5088 6.88043 22.5088 7.42934C22.5088 7.97655 22.7321 8.51391 23.1202 8.90193C23.5063 9.28806 24.0438 9.51141 24.5909 9.51141C25.1398 9.51141 25.6754 9.28806 26.0634 8.90193C26.4515 8.51391 26.6729 7.97636 26.6729 7.42934C26.6729 6.88043 26.4515 6.34288 26.0634 5.95675C25.6773 5.56873 25.1398 5.34728 24.5909 5.34728Z" [attr.fill]="stroke"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1447_1469">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-instagram-color: #657A90;
        }
        svg path {
            stroke: var(--stroke-instagram-color);
        }
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class InstagramComponent {
    @Input() size: string = 'var(--svg-size)';
    @Input() stroke: string = 'var(--stroke-instagram-color)';
}
