<div class="box-header">
    <div class="header-wrapper">
        <div class="title-container">
            <div *ngIf="isUserLogged && !sidenav.opened" class="chat-history-container">
                <button id="id-chat-history"
                        type="button" class="chat-history-button" (click)="onOpenSidenav()">
                    <img src="assets/icons/chat-history.svg" alt="Chat History Icon">
                </button>
                <p class="button-tooltip">{{ chatHistoryTooltip }}</p>
            </div>
            <div class="button-outside-container">
                <div class="button-container">
                    <button id="id-new-chat" type="button" class="new-chat-button" (click)="newChat()"
                            [disabled]="!isInteractionAllowed || talkStarted">
                        <app-chat></app-chat>
                        <p *ngIf="!sidenav.opened" class="new-chat-text">{{ newChatButton }}</p>
                    </button>
                    <div *ngIf="isUserLogged" class="document-analysis-container" appOutsideClick (outsideClick)="closeDocumentAnalysis()">
                        <button id="id-document-analysis" type="button" class="new-chat-button" (click)="toggledocumentAnalysis()"
                                [class.clicked]="isdropdownDocumentAnalysisOpen || selectedDocument" [disabled]="!isInteractionAllowed || talkStarted">
                            <app-document-analysis></app-document-analysis>
                            <p *ngIf="!sidenav.opened" class="new-chat-text">{{ documentAnalysisButton }}</p>
                        </button>
                        <div *ngIf="isdropdownDocumentAnalysisOpen" class="document-analysis-dropdown">
                            <app-document-analysis-dropdown></app-document-analysis-dropdown>
                        </div>
                        <div *ngIf="selectedDocument" class="number-selected-document">1</div>
                    </div>
                    <button id="id-learning-hub" type="button" class="new-chat-button" (click)="learningHub()">
                        <app-learning-hub></app-learning-hub>
                        <p *ngIf="!sidenav.opened" class="new-chat-text">{{ learningHubButton }}</p>
                    </button>
                </div>
            </div>
            <p class="company-name-desktop">Exa<strong>Pro</strong></p>
            <div class="account-container">
                <app-account></app-account>
            </div>
        </div>
        <div class="inline-header">
            <p class="company-name-mobile">Exa<strong>Pro</strong></p>
            <app-languages></app-languages>
        </div>
    </div>
    <!--<hr class="line">-->
</div>
