import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { AttachmentDto } from "../dtos/attachmentDto";
import { ConfigService } from "./config.service";
import { HttpService } from "./http.service";
import { AnalyzingDocument } from "../models/analyzingDocument";
import { HttpResponseModel } from "../models/http.model";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class DocumentAnalysisService {
    documentsList: string[] = ['Document 1.pdf', 'Document 2.pdf', 'Image 3.jpg', 'Document 4.pdf', 'Image 5.jpg', 'Document 6.pdf', 'Image 7.jpg',
        'Document 8.pdf', 'Image 9.jpg', 'Document 10.pdf']

    documentsToAnalyse: AnalyzingDocument[] = [];
    analyzingDocument: BehaviorSubject<any> = new BehaviorSubject<AnalyzingDocument | null>(null);
    analyzingDocuments: BehaviorSubject<any> = new BehaviorSubject<AnalyzingDocument[]>(this.documentsToAnalyse);

    constructor(private http: HttpService, private config: ConfigService, private auth: AuthService) {}

    // getters
    private getDocumentAnalysisUrl(): string {
        return `${this.config.getHttpScheme()}://${this.config.getHost()}/misc`;
    }

    getAnalyzingDocument(): Observable<AnalyzingDocument | null> {
        return this.analyzingDocument;
    }

    getAnalyzingDocumentId(): string | null {
        let documentId: string | null = null;

        this.getAnalyzingDocument().subscribe(document => {
            if (document) {
                documentId = document.id;
            } else {
                documentId = null;
            }
        });

        return documentId;
    }

    getAnalyzingDocuments(): Observable<AnalyzingDocument[]> {
        return this.analyzingDocuments;
    }

    // setters
    setAnalyzingDocument(document: AnalyzingDocument | null): void {
        this.analyzingDocument.next(document);
    }

    setAnalyzingDocuments(documents: AnalyzingDocument[]): void {
        this.analyzingDocuments.next(documents);
    }

    async getDocumentsToAnalyse(): Promise<HttpResponseModel<AnalyzingDocument[]>> {
        const url = `${this.getDocumentAnalysisUrl()}/${this.auth.getUserId()}/document`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create<AnalyzingDocument[]>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }

        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: []
            };
        }
    }

    async addDocumentToAnalyse(file: File) {
        const url = `${this.getDocumentAnalysisUrl()}/${this.auth.getUserId()}/document`;

        try {
            const formData = new FormData();
            formData.append("file", new File([file], encodeURIComponent(file.name), { type: file.type }));

            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setContent(formData)
                .createAttachment<any>()
            );

            return {
                body: response.body,
                status: response.status,
                message: response.message
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: null
            };
        }
    }

    async deleteDocumentToAnalyse(documentId: string) {
        const url = `${this.getDocumentAnalysisUrl()}/${this.auth.getUserId()}/document/${documentId}`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("DELETE")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create()
            );

            return {
                body: response.body,
                status: response.status,
                message: response.message
            };
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: null
            };
        }
    }
}
