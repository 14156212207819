import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { ConfigService } from "./config.service";
import { LoginModel } from "../models/loginModel";
import { UserModel } from "../models/user";
import { HttpService } from "./http.service";
import { v4 as uuidv4 } from "uuid";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private http: HttpService,
        private config: ConfigService) {}

    // getters
    getUser() {
        const userData = localStorage.getItem("user");
        return userData ? JSON.parse(userData) as UserModel : null;
    }

    getUserId() {
        const user = this.getUser() as UserModel;
        return user.id;
    }

    // setters
    setUser(user: UserModel) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    async login(model: LoginModel) {
        const url = `${this.config.getUrl()}/auth/user/login`;
        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                })
                .setContent(model)
                .create<LoginModel>()
            );

            return {
                token: response.body?.token || '',
                status: response.status,
                message: response.message
            }
        } catch (e: any) {
            return {
                token: null,
                status: e.status,
                message: e.message
            }
        }
    }

    async isLogged(): Promise<boolean> {
        const token = this.config.getToken();
        if (token == null) return false;

        if (await this.validateToken(token)) {
            return true;
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            return false;
        }
    }

    private async validateToken(token: string): Promise<boolean> {
        const url = `${this.config.getUrl()}/auth/user/validate`;
        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                })
                .create<UserModel>()
            );

            if (response.body) {
               this.setUser(response.body);
            } else {
                this.setUser(<UserModel>{});
            }
            return response.status === 200;
        } catch (e: any) {
            return false;
        }
    }

    async logout() {
        const url = `${this.config.getUrl()}/auth/logout`;
        try {
            await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create()
            );

            localStorage.removeItem('token');
            localStorage.removeItem('user');
        } catch (e: any) {
            console.error(e);
        }
    }

    async forgotPassword (email: string) {
        const formData = new FormData();
        formData.append("email", email);

        await new Promise(resolve => setTimeout(resolve, 2000));
    }

    async changePassword (data: any) {
        const token = this.config.getToken();
        const url = `${this.config.getUrl()}/auth/user/change-password`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                })
                .setContent(data)
                .create<any>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
            };
        }
    }
}
