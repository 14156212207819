import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-correct',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 32 23" fill="none">
            <path d="M29.4849 2.60938L10.9408 20.6996L2.51172 12.4767" stroke="#31405A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class CorrectComponent {
    @Input() size: string = 'var(--svg-size)';
}
