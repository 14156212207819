import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-play',
    template: `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#FEFFFF"/>
            <path d="M11.3477 8.59766L21.4023 15L11.3477 21.4023V8.59766Z" fill="#31405A"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class PlayComponent {
    @Input() size: string = 'var(--svg-size)';
}
