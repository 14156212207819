import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-document-analysis',
    template: `
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 43712">
                <g id="Vector">
                    <path d="M15.8027 1H7.30273C6.7723 1 6.26359 1.21071 5.88852 1.58579C5.51345 1.96086 5.30273 2.46957 5.30273 3V11V19C5.30273 19.5304 5.51345 20.0391 5.88852 20.4142C6.26359 20.7893 6.7723 21 7.30273 21H19.3027C19.8332 21 20.3419 20.7893 20.7169 20.4142C21.092 20.0391 21.3027 19.5304 21.3027 19V6.5L15.8027 1Z" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.8027 1H7.30273C6.7723 1 6.26359 1.21071 5.88852 1.58579C5.51345 1.96086 5.30273 2.46957 5.30273 3V11V19C5.30273 19.5304 5.51345 20.0391 5.88852 20.4142C6.26359 20.7893 6.7723 21 7.30273 21H19.3027C19.8332 21 20.3419 20.7893 20.7169 20.4142C21.092 20.0391 21.3027 19.5304 21.3027 19V6.5L15.8027 1Z" stroke="black" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <g id="Group 43709">
                    <g id="Vector_2">
                        <path d="M6.00417 15.0591C8.60071 15.0591 10.7056 12.9542 10.7056 10.3577C10.7056 7.76116 8.60071 5.65625 6.00417 5.65625C3.40764 5.65625 1.30273 7.76116 1.30273 10.3577C1.30273 12.9542 3.40764 15.0591 6.00417 15.0591Z" fill="#F5F7FA"/>
                        <path d="M6.00417 15.0591C8.60071 15.0591 10.7056 12.9542 10.7056 10.3577C10.7056 7.76116 8.60071 5.65625 6.00417 5.65625C3.40764 5.65625 1.30273 7.76116 1.30273 10.3577C1.30273 12.9542 3.40764 15.0591 6.00417 15.0591Z" [attr.stroke]="stroke" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.00417 15.0591C8.60071 15.0591 10.7056 12.9542 10.7056 10.3577C10.7056 7.76116 8.60071 5.65625 6.00417 5.65625C3.40764 5.65625 1.30273 7.76116 1.30273 10.3577C1.30273 12.9542 3.40764 15.0591 6.00417 15.0591Z" stroke="black" stroke-opacity="0.2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <g id="Vector_3">
                        <path d="M11.8805 16.2341L9.35352 13.707" stroke="#657A90" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.8805 16.2341L9.35352 13.707" stroke="black" stroke-opacity="0.2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </g>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --document-analysis-color: #657A90;
        }
        svg g path {
            stroke: var(--document-analysis-color);
        }
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class DocumentAnalysisComponent {
    @Input() stroke: string = 'var(--document-analysis-color)';
    @Input() size: string = 'var(--svg-size)';
}
