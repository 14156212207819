<div class="chat-history-title">
    <h2>{{ chatHistoryTitle }}</h2>
    <div class="chat-history-container">
        <button id="id-chat-history" type="button" class="chat-history-button" (click)="sidenav.close()">
            <img [src]="chatHistoryIcon" alt="Chat History Icon">
        </button>
        <p class="button-tooltip">{{ chatHistoryTooltip }}</p>
    </div>
</div>
<div class="sidenav-list-container">
    <mat-nav-list *ngIf="isHistoryExists" [class.disabled]="!isInteractionAllowed || talkStarted">
        <mat-list-item *ngFor="let conversation of conversations" (click)="handleHistorycalMessage(conversation.conversationUuid)"
                       [class.selected-conversation]="currentConversationId === conversation.conversationUuid" disableRipple>
            <div class="item-container">
                <p> {{ conversation.conversationName }} </p>
                <button id="id-delete-conversation" type="button" class="delete-conversation-button" (click)="deleteConversation(conversation.conversationUuid, $event)">
                    <img [src]="deleteIcon" alt="Delete Icon">
                </button>
            </div>
        </mat-list-item>
    </mat-nav-list>
    <div *ngIf="!isHistoryExists" class="no-history-container">
        <p>{{ noChatHistory }}</p>
    </div>
</div>
