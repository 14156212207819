<mat-sidenav-container class="sidenav-container" [dir]="direction">
    <mat-sidenav  #sidenav mode="side" opened="false">
        <app-sidenav *ngIf="isUserLogged" [sidenav]="sidenav"></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="box-landing" [ngClass]="{'blurred': isBlured, 'talk-started': talkStarted}">
            <div class="gradient-landing"></div>
            <div class="inline-landing">
                <header>
                    <app-header [sidenav]="sidenav"></app-header>
                </header>
                <div class="inline-content">
                    <div class="scroll-container" #scrollContainer>
                        <div class="conversation-container">
                            <app-introduction></app-introduction>
                            <app-conversation></app-conversation>
                        </div>
                        <div *ngIf="getIsShowCharacter" class="character-container">
                            <app-character></app-character>
                        </div>
                    </div>
                    <div class="related-container">
                        <app-related-questions></app-related-questions>
                    </div>
                    <div class="controls-container">
                        <app-conversation-controls></app-conversation-controls>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<app-lost-network></app-lost-network>
<app-feedback idModal="feedbackModal" labelModal="feedbacklabelModal" [dir]="direction"></app-feedback>
<app-content-modal idModal="idModal" labelModal="labelModal" [dir]="direction"></app-content-modal>
<app-change-password-modal idModal="changePasswordModal" labelModal="changePasswordlabelModal" [dir]="direction"></app-change-password-modal>




<!--<div class="box-landing" [ngClass]="{'blurred': isBlured, 'talk-started': talkStarted}" [dir]="direction">
    <div class="gradient-landing"></div>
    <div class="inline-landing">
        <header>
            <div class="header-container" [class.moved]="isVisibleSidenav">
                <app-header></app-header>
            </div>
            <div *ngIf="isVisibleSidenav" class="sidenav-container" @slideInOut>
                <app-sidenav></app-sidenav>
            </div>
        </header>

        <div class="inline-content">
            <div class="scroll-container" #scrollContainer>
                <div class="conversation-container">
                    <app-introduction></app-introduction>
                    <app-conversation></app-conversation>
                </div>
                <div class="character-container">
                    <app-character></app-character>
                </div>
            </div>
            <div class="related-container">
                <app-related-questions></app-related-questions>
            </div>
            <div class="controls-container">
                <app-conversation-controls></app-conversation-controls>
            </div>
        </div>
    </div>
</div>-->
