import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dislike',
    template: `
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M1.40314 6.18635C0.92129 6.759 0.857117 7.55813 1.28886 8.08226C1.53153 8.37703 1.90426 8.54609 2.31153 8.54609L5.45806 8.54609C5.35304 8.90309 5.14041 9.33748 5.14041 10.0773C5.14041 11.1089 5.58549 12.3117 6.99823 12.3117C7.1701 12.3117 7.31677 12.1875 7.34502 12.018C7.60489 10.4589 8.65053 9.44171 9.23171 7.79297L11.8936 7.79297C12.0877 7.79297 12.2451 7.63557 12.2451 7.44141L12.2451 1.03983C12.2451 0.845675 12.0877 0.688269 11.8936 0.688269L3.57301 0.688268C3.18359 0.688268 2.79395 0.837214 2.47585 1.10766C1.90098 1.59621 1.71008 2.36164 1.97478 2.94469C1.57116 3.42427 1.46059 4.06264 1.68896 4.56544C1.28539 5.04504 1.17479 5.68355 1.40314 6.18635ZM9.60917 1.39139L11.542 1.39139L11.542 7.08985L9.60917 7.08985L9.60917 1.39139ZM2.07376 6.50597C2.2201 6.38159 2.23955 6.1628 2.11745 6.01456C1.87482 5.72004 1.9857 5.20278 2.35955 4.8851C2.50592 4.76072 2.52538 4.54191 2.40327 4.39367C2.15652 4.09411 2.2651 3.58751 2.64535 3.26435C2.7917 3.13999 2.81117 2.92123 2.68911 2.77299C2.44234 2.47329 2.55092 1.96657 2.93122 1.64337C3.11961 1.48325 3.35352 1.39139 3.57301 1.39139L8.90605 1.39139L8.90605 5.55858C8.90605 8.32107 7.21756 9.4392 6.71574 11.5815C6.59743 11.557 5.84354 11.4223 5.84354 10.0773C5.84354 9.19015 6.22011 8.85596 6.22011 8.19453C6.22011 8.00037 6.0627 7.84296 5.86855 7.84296L2.31153 7.84296C2.11231 7.84296 1.9419 7.76923 1.83163 7.63531C1.5849 7.33575 1.69348 6.82915 2.07376 6.50597Z" [attr.fill]="stroke" [attr.stroke]="stroke" stroke-width="0.2"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class DislikeComponent {
    @Input() size: string = 'var(--svg-size)';
    @Input() stroke: string = '#31405A';
}
