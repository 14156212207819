import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";

import { AttachmentDto } from "../../../core/dtos/attachmentDto";
import { AlertService } from "../../../core/services/alert.service";
import { MultimediaService } from "../../../core/services/multimedia.service";
import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";
import { ConfigService } from "../../../core/services/config.service";
import { DocumentAnalysisService } from "../../../core/services/document-analysis.service";
import { AnalyzingDocument } from "../../../core/models/analyzingDocument";

@Component({
    selector: 'app-document-analysis-dropdown',
    templateUrl: './document-analysis-dropdown.component.html',
    styleUrl: './document-analysis-dropdown.component.scss'
})
export class DocumentAnalysisDropdownComponent implements OnInit, OnDestroy {
    @ViewChild('fileInputDocument') fileInputRef!: ElementRef<HTMLInputElement>;
    addDocumentIcon = 'assets/icons/add-document.svg';
    isExpanded = false;
    displayedFiles: AnalyzingDocument[] = [];
    files: AnalyzingDocument[] = [];
    selectedFile: AnalyzingDocument | null = null;
    addFileLabel = 'Add file';
    maxFilesLimit: number = 10;
    maxFileSize = 50 * 1024 * 1024; // 50 MB

    fileSubscripiton!: Subscription;
    filesSubscripiton!: Subscription;
    translateSubscription!: Subscription;

    constructor(private alert: AlertService,
                private multimedia: MultimediaService,
                private event: EventService,
                private config: ConfigService,
                private documentAnalysis: DocumentAnalysisService,
                private language: LanguageService) { }

    async ngOnInit() {
        this.fileSubscripiton = this.documentAnalysis.getAnalyzingDocument().subscribe(file => {
            this.selectedFile = file;
        });

        this.filesSubscripiton = this.documentAnalysis.getAnalyzingDocuments().subscribe(files => {
            this.files = files;
            this.updateDisplayedDocuments();
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.addFileLabel = translate.typography.addFileLabel;
        });

        this.updateDisplayedDocuments();
    }

    get getFilesLimit(): boolean {
        return this.files.length > 5;
    }

     getDocumentName(document: AnalyzingDocument): string {
        return document.displayName + '.' + document.extension;
    }

    isFileSelected(file: AnalyzingDocument): boolean {
        return this.selectedFile?.id === file.id;
    }

    async removeFile(file: AnalyzingDocument, event: Event) {
        event.stopPropagation();

        if (this.isFileSelected(file)) {
            this.selectedFile = null;
            this.documentAnalysis.setAnalyzingDocument(null);
        }

        await this.deleteAnalyzingDocument(file.id);
    }

    hasUploadFiles() {
        return this.files.length > 0;
    }

    updateDisplayedDocuments() {
        if (this.config.isMobileDevice()) {
            this.displayedFiles = this.files;
        } else {
            this.displayedFiles = this.isExpanded ? this.files : this.files.slice(0, 5);
        }
    }

    expandDocumentList(event: Event) {
        event.stopPropagation();
        this.isExpanded = true;
        this.updateDisplayedDocuments();
    }

    collapseDocumentList(event: Event) {
        event.stopPropagation();
        this.isExpanded = false;
        this.updateDisplayedDocuments();
    }

    chooseFile(analyzingFile: AnalyzingDocument) {
        if (this.selectedFile === analyzingFile) {
            this.selectedFile = null;
            this.documentAnalysis.setAnalyzingDocument(null);
        } else {
            this.selectedFile = analyzingFile;
            this.documentAnalysis.setAnalyzingDocument(analyzingFile);
        }
    }

    async onAddAttachments() {
        if (this.files.length >= this.maxFilesLimit) {
            await this.alert.showError('Error', 'You can upload up to 10 files. Please delete some before adding new ones.');
        } else {
            this.fileInputRef.nativeElement.click();
        }
    }

    getFileNameWithoutExtension(name: string) {
        return name.replace(/\.[^/.]+$/, '');
    }

    async onFileSelect(event: any) {
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const sanitizedFileName = file.name.replace(/\s+/g, '_'); // Add underscore instead of spaces
            const sanitizedFile = new File([file], sanitizedFileName.toLowerCase(), { type: file.type }); // Lowercase the file name

            if (sanitizedFile.size <= this.maxFileSize) {
                await this.handleDroppedFiles(sanitizedFile);
            } else {
                await this.alert.showError('Error', `File exceeds the 50MB size limit and will not be uploaded.`)
            }
        }

        this.fileInputRef.nativeElement.value = '';
    }

    async handleDroppedFiles(file: File | {}) {
        if (file instanceof File) {
            await this.addAnalyzingDocument(file);
        }
    }

    async getDocumentsToAnalyse() {
        this.alert.showLoading();
        const response = await this.documentAnalysis.getDocumentsToAnalyse();

        if (response.status !== 200) {
            await this.alert.showError('Error', 'Error getting the documents for analysis. Please try again.');
            return;
        } else {
            this.alert.close();
            this.documentAnalysis.setAnalyzingDocuments(response.body);
        }
    }

    async addAnalyzingDocument(file: File) {
        this.alert.showLoading();
        const documentResponse = await this.documentAnalysis.addDocumentToAnalyse(file);

        if (documentResponse.status !== 200) {
            await this.alert.showError('Error', 'Error adding the document for analysis. Please try again.');
            return;
        }

        await this.getDocumentsToAnalyse();
    }

    async deleteAnalyzingDocument(id: string) {
        this.alert.showLoading();
        const documentResponse = await this.documentAnalysis.deleteDocumentToAnalyse(id);

        if (documentResponse.status !== 200) {
            await this.alert.showError('Error', 'Error removing the document for analysis. Please try again.');
            return;
        }

        await this.getDocumentsToAnalyse();
    }

    ngOnDestroy(): void {
        this.fileSubscripiton.unsubscribe();
        this.filesSubscripiton.unsubscribe();
        this.translateSubscription.unsubscribe();
    }
}
