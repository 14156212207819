import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chat',
    template: `
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0153 2.09441C6.48875 2.1379 2.05082 6.64546 2.09431 12.1728C2.10811 13.9267 2.57904 15.6362 3.45958 17.1402L2.20265 21.1514C2.11498 21.431 2.19123 21.7362 2.40007 21.9418C2.60691 22.1454 2.91253 22.2196 3.19348 22.1267L7.18438 20.8069C8.70209 21.6636 10.4187 22.1076 12.1727 22.0938C17.6992 22.0503 22.1372 17.5427 22.0937 12.0154C22.0502 6.48885 17.5426 2.05092 12.0153 2.09441ZM12.1604 20.5313C10.5761 20.5438 9.02835 20.1139 7.68438 19.2881C7.49102 19.1694 7.25182 19.1388 7.03007 19.2121L4.14914 20.165L5.05656 17.2694C5.12533 17.0497 5.0937 16.8107 4.97009 16.6164C4.12341 15.2856 3.66923 13.7448 3.65676 12.1605C3.62015 7.50823 7.37535 3.69347 12.0276 3.65686C16.6799 3.62025 20.4946 7.37544 20.5312 12.0277C20.5678 16.68 16.8126 20.4947 12.1604 20.5313Z" [attr.fill]="stroke"/>
            <path d="M7.80859 12.1279L16.3798 12.0605" [attr.stroke]="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.0605 7.80859L12.128 16.3798" [attr.stroke]="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        :host {
            --chat-color: #657A90;
        }
        svg g path {
            stroke: var(--chat-color);
        }
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class ChatComponent {
    @Input() stroke: string = 'var(--chat-color)';
    @Input() size: string = 'var(--svg-size)';
}
