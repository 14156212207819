import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { AccountItem } from "../../../core/models/accountItem";
import { ConfigService } from "../../../core/services/config.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../core/services/alert.service";
import { RouterService } from "../../../core/services/router.service";
import { AnimationService } from "../../../core/services/animation.service";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit, OnDestroy {
    changePasswordItem: AccountItem = { icon: 'assets/icons/settings.svg', name: 'Change password' };
    logoutItem: AccountItem = { icon: 'assets/icons/logout.svg', name: 'Logout' };
    accountImage: string = 'assets/icons/account.svg';

    isInteractionAllowed!: boolean;
    isVisibleMenu!: boolean;
    agentName!: string;
    talkStarted!: boolean;

    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    visibilitySubscription!: Subscription;
    talkSubscription!: Subscription;

    constructor(
        private elementRef: ElementRef,
        private language: LanguageService,
        private interaction: InteractionService,
        private config: ConfigService,
        private visibility: VisibilityService,
        private auth: AuthService,
        private alert: AlertService,
        private router: RouterService,
        private event: EventService,
        private animation: AnimationService) {
    }

    ngOnInit(): void {
        this.agentName = this.config.getAgentName();

        this.visibilitySubscription = this.visibility.getVisibility('avatar-account-menu').subscribe(visible => {
            this.isVisibleMenu = visible;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.changePasswordItem.name = translate.typography.settings;
            this.logoutItem.name = translate.typography.logout;
        });
    }

    toggleDropdown() {
        this.visibility.toggleComponent('avatar-account-menu');
    }

    closeDropdown() {
        this.visibility.hideComponent('avatar-account-menu');
    }

    async changePassword() {

    }

    async logout() {
        this.alert.showLoading();
        this.animation.destroyAnimationApp();
        await this.auth.logout();
        this.router.goToSignIn();
        this.alert.close();
    }

    ngOnDestroy(): void {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
    }
}
