import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-stop',
    template: `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.833984" y="0.83342" width="18.3333" height="18.3333" rx="4" fill="#FBFBFB"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class StopComponent {
    @Input() size: string = 'var(--svg-size)';
}
