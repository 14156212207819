import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-document',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="47" viewBox="0 0 38 47" fill="none">
            <path d="M24.3125 2H6.25C5.12283 2 4.04183 2.45303 3.2448 3.25944C2.44777 4.06585 2 5.15957 2 6.3V40.7C2 41.8404 2.44777 42.9342 3.2448 43.7406C4.04183 44.547 5.12283 45 6.25 45H31.75C32.8772 45 33.9582 44.547 34.7552 43.7406C35.5522 42.9342 36 41.8404 36 40.7V13.825L24.3125 2Z" [attr.fill]="stroke" [attr.stroke]="stroke" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        :host {
            --document-color: #C6D0DA;
        }
        svg g path {
            stroke: var(--document-color);
        }
        svg {
            width: var(--svg-width-size);
            height: var(--svg-height-size);
        }
    `]
})
export class DocumentComponent {
    @Input() heightSize: string = 'var(--svg-height-size)';
    @Input() widthSize: string = 'var(--svg-width-size)';
    @Input() stroke: string = 'var(--document-color)';
}
