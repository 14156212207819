<div class="box-account" appOutsideClick (outsideClick)="closeDropdown()">
    <div class="account-wrapper">
        <!--<p class="account-name">{{ agentName }}</p>-->
        <button id="id-account" class="account-container" [disabled]="!isInteractionAllowed || talkStarted"
            (click)="toggleDropdown()" tabindex="0"
        >
            <div *ngIf="isVisibleMenu" class="account-menu">
                <div id="id-change-password-option" class="menu-item" (click)="changePassword()" data-bs-toggle="modal"
                     data-bs-target="#changePasswordModal" data-target=".bd-example-modal-lg" tabindex="0">
                    <img class="menu-item-img" [src]="changePasswordItem.icon" alt="Change password icon">
                    <p class="menu-item-name">{{ changePasswordItem.name }}</p>
                </div>
                <div id="id-logout-option" class="menu-item" (click)="logout()" tabindex="0">
                    <img class="logout-item-img" [src]="logoutItem.icon" alt="Logout icon">
                    <p class="menu-item-name">{{ logoutItem.name }}</p>
                </div>
            </div>
            <img class="button-background" [src]="accountImage" alt="Account icon">
        </button>
    </div>
</div>
