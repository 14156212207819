import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-like',
    template: `
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M11.842 6.81364C12.3238 6.24099 12.388 5.44186 11.9563 4.91773C11.7136 4.62296 11.3409 4.4539 10.9336 4.4539H7.78705C7.89208 4.0969 8.1047 3.66251 8.1047 2.92266C8.1047 1.89113 7.65963 0.688271 6.24688 0.688271C6.07502 0.688271 5.92834 0.812513 5.9001 0.982036C5.64023 2.54105 4.59459 3.55829 4.01341 5.20702H1.35156C1.15741 5.20702 1 5.36443 1 5.55858V11.9602C1 12.1543 1.15741 12.3117 1.35156 12.3117H9.67211C10.0615 12.3117 10.4512 12.1628 10.7693 11.8923C11.3441 11.4038 11.535 10.6384 11.2703 10.0553C11.674 9.57572 11.7845 8.93736 11.5562 8.43455C11.9597 7.95495 12.0703 7.31644 11.842 6.81364ZM3.63595 11.6086H1.70312V5.91015H3.63595V11.6086ZM11.1714 6.49402C11.025 6.6184 11.0056 6.83719 11.1277 6.98543C11.3703 7.27995 11.2594 7.79722 10.8856 8.11489C10.7392 8.23927 10.7197 8.45808 10.8419 8.60633C11.0886 8.90588 10.98 9.41248 10.5998 9.73564C10.4534 9.86 10.4339 10.0788 10.556 10.227C10.8028 10.5267 10.6942 11.0334 10.3139 11.3566C10.1255 11.5167 9.8916 11.6086 9.67211 11.6086H4.33907V7.44141C4.33907 4.67893 6.02755 3.56079 6.52938 1.41847C6.64769 1.44303 7.40158 1.5777 7.40158 2.92266C7.40158 3.80984 7.02501 4.14404 7.02501 4.80547C7.02501 4.99962 7.18241 5.15703 7.37657 5.15703H10.9336C11.1328 5.15703 11.3032 5.23076 11.4135 5.36469C11.6602 5.66424 11.5516 6.17084 11.1714 6.49402Z" fill="#31405A" stroke="#31405A" stroke-width="0.2"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class LikeComponent {
    @Input() size: string = 'var(--svg-size)';
}
