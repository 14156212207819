import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import {
    Attachment,
    UserAttachment,
    WebSocketFeedback,
    WebSocketFormatedContent,
    WebSocketMessage
} from "../models/webSocketMessage";
import { WebSocketService } from "./web-socket.service";
import { LanguageService } from "./language.service";
import { HttpService } from "./http.service";
import { ConfigService } from "./config.service";
import { MessageDisplayFormat } from "../models/messageDisplayFormat";
import { VisibilityService } from "./visibility.service";
import { EventService } from "./event.service";
import { AttachmentDto } from "../dtos/attachmentDto";
import { AlertService } from "./alert.service";
import { RelatedQuestion } from "../models/relatedQuestion";
import { MultimediaService } from "./multimedia.service";
import { DocumentAnalysisService } from "./document-analysis.service";
import { AnonymousService } from "./anonymous.service";
import { ConversationService } from "./conversation.service";

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    currentConversationId: string | null = null;
    messageIndex: number | null = null;
    //messages: MessageDisplayFormat[] = [];
    messagesObject: MessageDisplayFormat[] = [];
    messages: BehaviorSubject<MessageDisplayFormat[]> = new BehaviorSubject<MessageDisplayFormat[]>([]);

    messageRelatedQuestions: BehaviorSubject<RelatedQuestion[]> = new BehaviorSubject<RelatedQuestion[]>([]);
    avatarSpeakingText: BehaviorSubject<string> = new BehaviorSubject<string>('');
    avatarSpeakingMode: BehaviorSubject<string> = new BehaviorSubject<string>('');

    fileUrlPattern = /\[([^\]]+)\]\((http:\/\/[^\s]+|https:\/\/[^\s]+)\)/g;

    constructor(
        private config: ConfigService,
        private event: EventService,
        private http: HttpService,
        private language: LanguageService,
        private socket: WebSocketService,
        private visibility: VisibilityService,
        private alert: AlertService,
        private documentAnalysis: DocumentAnalysisService,
        private anonymous: AnonymousService,
        private conversation: ConversationService,
        private multimedia: MultimediaService) {
            this.conversation.getCurrentConversation().subscribe(conversationId => {
                this.currentConversationId = conversationId;
                console.log('Current conversation id: ' + this.currentConversationId)
            });
    }

    // getters
    getMessages(): Observable<MessageDisplayFormat[]> {
        return this.messages;
    }

    getMessageIndex(): number | null {
        return this.messageIndex ?? null;
    }

    getMessageRelatedQuestion(): Observable<RelatedQuestion[]> {
        return this.messageRelatedQuestions;
    }

    getCurrentAvatarSpeakingText(): Observable<string> {
        return this.avatarSpeakingText;
    }

    getCurrentAvatarSpeakingMode(): Observable<string> {
        return this.avatarSpeakingMode;
    }

    // setters
    setMessage (author: string, message: WebSocketMessage) {
        if (author == 'ai') {
            if (!this.currentConversationId) {
                this.conversation.setCurrentConversation(message.conversationId); // Set current conversation for new conversation
                this.event.setNewConversationStarted(true);
            }

            if (!message.query) {
                message.query = "Error: This message does not have assigned content.";
                message.formattedContent = this.formatContent("Error: This message does not have assigned content.");
                this.setCurrentAvatarSpeakingText(message.query);
                return;
            }

            if (!message.conversationId) {
                console.log('No conversation id found in message'); // Current conversation cant be created, current messages wont be saved
            }

            this.setCurrentAvatarSpeakingText(message.query);

            message.attachments = [];
            message.attachments = this.extractAttachmentsFromContent(message);

            // ! is markdown for indicate image link
            message.query = this.replaceFileUrls(message.query);

            // Formate message content because of csv tables showing into html file
            // FormattedContent has type of text or csv and value of content
            message.formattedContent = this.formatContent(message.query);
        }

        let m = this.createMessageDisplay(author, message);

        this.messagesObject.push(m);
        this.setMessages(this.messagesObject);

        // TODO remove from here
        if (author == "ai") {
            this.visibility.hideComponent("avatar-loader");
        } else if (author == "client") {
            this.visibility.showComponent("avatar-loader");
        }

        this.event.scrollToBottomEvent.emit();
    }

    setMessageFeedback (index: number, feedback: boolean, feedbackContent?: string, feedbackReason?: string) {
        const message = this.findMessageByIndex(index);
        if (message) {
            message.feedback = feedback;
            this.messagesObject[index] = message;

            const feedbackWS: WebSocketFeedback = {
                type: "feedback",
                feedback_id: message.id,
                feedback_status: message.feedback,
                feedback_message: feedbackContent ?? "",
                feedback_reason: feedbackReason ?? ""
            }

            this.sendFeedback(feedbackWS).then();
        }
    }

    setMessageIndex(index: number) {
        this.messageIndex = index;
    }

    setMessageRelatedQuestion(relatedQuestions: RelatedQuestion[]): void {
        return this.messageRelatedQuestions.next(relatedQuestions);
    }

    setMessages(messages: MessageDisplayFormat[]): void {
        return this.messages.next(messages);
    }

    setCurrentAvatarSpeakingText(text: any): void {
        return this.avatarSpeakingText.next(text);
    }

    setCurrentAvatarSpeakingMode(mode: string): void {
        return this.avatarSpeakingMode.next(mode);
    }

    createMessageDisplay(author: string, message: WebSocketMessage) {
        let messageObj: MessageDisplayFormat = {
            id: message.id ?? "",
            author: author,
            content: message.query,
            conversationId: message.conversationId,
            questionId: message.questionId,
            language: message.language,
            direction: this.language.getLanguage(message.language).direction,
            attachments: message.attachments,
            audioAnswerUrl: message.audioAnswer,
            formattedContent: message.formattedContent,
            currentRowsIndex: 0,
            dataIndex: 0
        }

        return messageObj;
    }

    clearMessages () {
        this.messagesObject = [];
        this.setMessages(this.messagesObject);
    }

    extractAttachmentsFromContent(message: WebSocketMessage) {
        const attachments: Attachment[] = [];
        const matches = message.query.match(this.fileUrlPattern);

        if (matches) {
            matches.forEach((match) => {
                const url = match.match(/\((http:\/\/[^\s]+|https:\/\/[^\s]+)\)/)?.[1];
                if (url) {
                    const fileNameWithParams = url.split('/').pop();
                    const nameMatch = fileNameWithParams ? fileNameWithParams.split('?')[0] : fileNameWithParams;

                    if (nameMatch) {
                        const allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'docx', 'txt', 'mp4'];
                        const fileExtension = nameMatch.split('.').pop()?.toLowerCase();

                        if (fileExtension && allowedExtensions.includes(fileExtension)) {
                            const type = this.multimedia.getAttachmentType(nameMatch);
                            const newAttachment = new Attachment(type, url, nameMatch ?? null);
                            attachments.push(newAttachment);
                        }
                    }
                }
            });
        }

        return attachments;
    }

    findMessageByIndex(index: number) {
        return this.messagesObject.find((_, i) => i === index);
    }

    async editMessage(index: number, content: string) {
        let questionId = null;
        let conversationId = null;

        const messageQuestion = this.findMessageByIndex(index);
        const messageAnswer = this.findMessageByIndex(index + 1);

        if (messageQuestion) {
            if (messageAnswer) {
                questionId = messageAnswer.questionId; // Take questionId and conversationId from answer message
                conversationId = messageAnswer.conversationId;
            }

            messageQuestion.content = content;
            this.messagesObject[index] = messageQuestion;
            this.messagesObject.splice(index + 1);
            this.setMessages(this.messagesObject);

            const messageWS: WebSocketMessage = {
                type: "text",
                mode: 'text',
                query: content,
                conversationId: conversationId,
                questionId: questionId,
                formattedContent: this.formatContent(content),
                language: this.language.getSelectedLanguage()?.locale,
                token: this.config.getToken(),
                device: this.config.getDevice(),
                documentAnalysisId: this.documentAnalysis.getAnalyzingDocumentId()
            }

            this.visibility.showComponent("avatar-loader");

            this.event.scrollToBottomEvent.emit();
            await this.submitConversationRequest(messageWS);
        }
    }

    createAttachmentsDisplay (files: any) {
        const attachments = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const newAttachment = new Attachment(file.type, file.url, file.name);
            attachments.push(newAttachment);
        }

        return attachments;
    }

    replaceFileUrls(content: string): string {
        content = content.replace(/!\[([^\]]+)\]\((http:\/\/[^\s]+|https:\/\/[^\s]+)\)/g, '[$1]($2)');
        content = content.replaceAll(this.fileUrlPattern, '<a href="$2" target="_blank">$1</a>');
        return content;
    }

    formatContent(content: string): WebSocketFormatedContent[] {
        const csvPattern = /```?csv\n([\s\S]*?)\n```/g;
        const parts: WebSocketFormatedContent[] = [];
        let lastIndex = 0;

        content.replace(csvPattern, (match, csvContent, offset) => {
            if (offset > lastIndex) {
                const textPart: WebSocketFormatedContent = {
                    type: 'text',
                    value: content.slice(lastIndex, offset),
                };

                parts.push(textPart);
            }

            lastIndex = offset + match.length;

            const rows = csvContent.split('\n').map((row: any) => row.split(',').map((cell: any) => cell.trim()));
            const headers = rows.shift() || [];

            const csvPart: WebSocketFormatedContent = {
                type: 'csv',
                value: { headers, rows },
            };

            parts.push(csvPart);

            return match;
        });

        if (lastIndex < content.length) {
            const textPart: WebSocketFormatedContent = {
                type: 'text',
                value: content.slice(lastIndex)
            };

            parts.push(textPart);
        }

        return parts;
    }

    async createConversationRequest (type: string, mode:string, currentInputValue: string, audioId?: string, files?: AttachmentDto[]) {
        this.visibility.showComponent("avatar-conversation");
        this.setCurrentAvatarSpeakingMode(mode);

        let formContent;

        if (currentInputValue) {
            formContent = this.formatContent(currentInputValue);
        } else {
            formContent = [] as WebSocketFormatedContent[];
        }

        // TODO token is temp here
        const message: WebSocketMessage = {
            type: type,
            query: currentInputValue,
            conversationId: this.currentConversationId,
            questionId: null,
            language: this.language.getSelectedLanguage()?.locale,
            mode: mode,
            token: this.config.getToken(),
            device: this.config.getDevice(),
            documentAnalysisId: this.documentAnalysis.getAnalyzingDocumentId(),
            formattedContent: formContent
        }

        // Prepare client attachments to display
        message.attachments = [];
        if (files && files.length !== 0) {
            try {
                const attachmentResponses = await Promise.all(
                    files.map(file => this.processAttachment(file.file))
                );

                const allSuccessful = attachmentResponses.every(response => response.status === 200 && response.body === true);

                if (allSuccessful) {
                    message.attachments = this.createAttachmentsDisplay(files);

                    message.user_attachments = files.map((file) => {
                        const sanitizedFileName = file.file.name.replace(/\s+/g, '_');
                        return new UserAttachment(sanitizedFileName);
                    });
                } else {
                    message.attachments = [];
                    console.error('Not all attachments were processed successfully');
                }
            } catch (error) {
                console.error('Error while processing attachments:', error);
            }
        }

        if (type === "audio") {
            message.query = audioId ?? "not found";
        } else if (type === "text") {
            this.setMessage("client", message);
        }

        try {
            await this.submitConversationRequest(message);
        } catch (e: any) {
            console.log('Error while submit conversation request: ' + e)
        }
    }

    private async processAttachment(file: File) {
        try {
            const url = `https://storage-service.dev.exafysolutions.ae/storage/store/documents`;

            const formData = new FormData();
            formData.append("file", new File([file], encodeURIComponent(file.name), { type: file.type }));

            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setContent(formData)
                .createAttachment<any>()
            );

            return {
                body: response.body,
                status: response.status,
                message: response.message
            }
        } catch (error) {
            console.error('Error processing attachment:', error);
            throw error;
        }
    }

    // events
    async sendFeedback (feedback: WebSocketFeedback) {
        try {
            this.socket.sendFeedback(feedback);

            if (!feedback.feedback_status) {
                this.alert.showSucess('Feedback succesfully sent', 'Feedback sucessfully provided.');
            }
        } catch (e: any) {
            throw new Error("Could not push feedback");
        }
    }

    async submitConversationRequest (data: WebSocketMessage) {
        try {
            this.socket.sendMessage(data);
        } catch (e: any) {
            if (e instanceof ProgressEvent || e.status === 0) {
                const lang = this.language.getSelectedLanguage().locale;

                const message: WebSocketMessage = {
                    id: "lost-net",
                    type: 'text',
                    mode: 'text',
                    query: this.language.getDesignTranslation(lang).typography.lostNet,
                    conversationId: null,
                    questionId: null,
                    language: lang,
                    token: this.config.getToken(),
                    device: this.config.getDevice(),
                    documentAnalysisId: null,
                    formattedContent: this.formatContent(this.language.getDesignTranslation(lang).typography.lostNet)
                }

                this.setMessage("ai", message)
                return;
            }
            throw e;
        }
    }
    async submitAudioData (audioData: File) {
        const anonymousId = await this.anonymous.getAnonymousId();
        const formData = new FormData();
        formData.append("audio", audioData);

        try {
            return await lastValueFrom(
                this.http
                .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/storage/stt-voice`)
                .setMethod("POST")
                .setHeaders({
                    "Authorization": `Bearer ${anonymousId}`
                })
                .setContent(formData)
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not submit audio data: ${e}`)
        }
    }
}
