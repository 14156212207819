import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from "../../../core/services/config.service";
import { LanguageService } from "../../../core/services/language.service";
import { MessageService } from "../../../core/services/message.service";
import { MicrophoneService } from "../../../core/services/microphone.service";
import { ConversationService } from "../../../core/services/conversation.service";

@Component({
    selector: 'app-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrl: './shortcuts.component.scss'
})
export class ShortcutsComponent implements OnInit, OnDestroy {
    shortcutsList: any[] = [];
    isInteractionAllowed!: boolean;

    translateSubscription!: Subscription;

    constructor(
        private config: ConfigService,
        private language: LanguageService,
        private message: MessageService,
        private conversation: ConversationService,
        protected microphone: MicrophoneService) { }

    ngOnInit() {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            this.shortcutsList = this.config.getShortcuts(selectedLanguage.locale);
        });
    }

    async onShortcutSubmit(value: string) {
        this.conversation.setCurrentConversation(null); // Set current conversation like unknown
        await this.message.createConversationRequest("text", "text", value);
    }

    ngOnDestroy() {
        if (this.translateSubscription) {this.translateSubscription.unsubscribe(); }
    }
}
