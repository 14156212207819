import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-image',
    template: `
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.4761 0H1.52383C0.682308 0 0 0.731001 0 1.63264V18.3674C0 19.269 0.682308 20 1.52383 20H22.4761C23.3177 20 24 19.269 24 18.3674V1.63264C24 0.731001 23.3177 0 22.4761 0ZM22.4761 1.63264V13.5065L19.4731 10.5812C19.0217 10.1416 18.3276 10.1592 17.8963 10.6216L14.8571 13.8775L8.86438 6.20814C8.4114 5.62854 7.58169 5.62284 7.1219 6.19549L1.52383 13.1661V1.63264H12H22.4761ZM16 5.91836C16 4.67835 16.9378 3.67344 18.0952 3.67344C19.2526 3.67344 20.1904 4.67835 20.1904 5.91836C20.1904 7.15837 19.2526 8.16323 18.0952 8.16323C16.9379 8.16328 16 7.15837 16 5.91836Z" fill="white"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-width-size);
            height: var(--svg-height-size);
        }
    `]
})
export class ImageComponent {
    @Input() heightSize: string = 'var(--svg-height-size)';
    @Input() widthSize: string = 'var(--svg-width-size)';
}
