import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import StreamingAvatar from "@heygen/streaming-avatar";

import { HeyGenService } from "../../../core/services/heygen.service";
import { MessageService } from "../../../core/services/message.service";
import { AlertService } from "../../../core/services/alert.service";
import { ConfigService } from "../../../core/services/config.service";
import { AvatarConfigRequest } from "../../../core/models/avatarConfig";
import { EventService } from "../../../core/services/event.service";
import { MicrophoneService } from "../../../core/services/microphone.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { LanguageService } from "../../../core/services/language.service";

@Component({
    selector: 'app-hey-gen-avatar',
    templateUrl: './hey-gen-avatar.component.html',
    styleUrl: './hey-gen-avatar.component.scss'
})
export class HeyGenAvatarComponent implements AfterViewInit, OnInit, OnDestroy {
    private videoElement!: HTMLVideoElement;
    private canvas!: HTMLCanvasElement;
    private avatar: StreamingAvatar | null = null;
    private sessionData: any = null;
    private avatarConfig: AvatarConfigRequest = { name: '', voiceId: '' };
    protected characterImage: string = '';
    private avatarSpeakingMode: string = '';
    talkStarted: boolean = false;
    isShowAvatarEnable: boolean = false;
    callMeButton: string = '';
    isEditing: boolean = false;
    isInteractionAllowed!: boolean;
    avatarEnabled: boolean = false;

    avatarConfigRequestSubscription!: Subscription;
    talkSubscription!: Subscription;
    startMobileStreamSubscription!: Subscription;
    showAvatarEnableSubscription!: Subscription;
    characterImageSubscription!: Subscription;
    avatarSpeakingTextSubscription!: Subscription;
    avatarSpeakingModeSubscription!: Subscription;
    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    editSubscription!: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private heygen: HeyGenService,
        private message: MessageService,
        private alert: AlertService,
        private config: ConfigService,
        private event: EventService,
        private language: LanguageService,
        private visibility: VisibilityService,
        private interaction: InteractionService,
        private microphone: MicrophoneService) { }

    ngOnInit(): void {
        this.avatarConfigRequestSubscription = this.config.getAvatarConfigRequest().subscribe(async config => {
            this.avatarConfig = config; // Take avatar config from new language

            if (!this.isMobile() && this.videoElement && this.isShowAvatarEnable) {
                await this.initializeAvatarSession(); // On language change start again heygen avatar initialization on PC
            }

            if (this.isMobile() && this.videoElement && this.talkStarted) {
                await this.initializeAvatarSession(); // If we change language on mobile device and talk is started, start heygen avatar initialization
            }
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.editSubscription = this.event.getStartedEdit().subscribe(isEditing => {
            this.isEditing = isEditing;
        });

        this.showAvatarEnableSubscription = this.event.getShowAvatarEnable().subscribe(async isAvatarShown => {
            this.isShowAvatarEnable = isAvatarShown;

            if (isAvatarShown && this.videoElement) {
                await this.initializeAvatarSession(); // On OK click start heygen avatar initialization on PC
            }
        });

        // On mobile call start heygen avatar initialization
        this.talkSubscription = this.event.getStartedTalk().subscribe(async isTalkStarted => {
            this.talkStarted = isTalkStarted;

            // Start heygen avatar initialization on mobile device
            if (this.isMobile() && this.talkStarted) {
                await this.initializeAvatarSession();
            }
        });

        this.characterImageSubscription = this.config.getCharacterImage().subscribe(image => {
            this.characterImage = image;
        });

        this.avatarSpeakingModeSubscription = this.message.getCurrentAvatarSpeakingMode().subscribe(mode => {
            if (mode) { this.avatarSpeakingMode = mode; }
        });

        this.avatarSpeakingTextSubscription = this.message.getCurrentAvatarSpeakingText().subscribe(async text => {
            if (!text || !this.avatarSpeakingMode || (this.isMobile() && !this.talkStarted)) return; // If mobile device and talk is not started, do not read messages

            if (this.config.getAvatarTalkOptions().includes(this.avatarSpeakingMode)) {
                await this.heygen.handleSpeak(text); // Avatar read received message
            }
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.callMeButton = translate.typography.callMeButton;
        });

        this.avatarEnabled = this.config.getAvatarEnabled();
    }

    async ngAfterViewInit() {
        this.videoElement = document.getElementById("avatarVideo") as HTMLVideoElement;
        this.canvas = document.getElementById("avatarCanvas") as HTMLCanvasElement;

        this.heygen.setAvatarVideo(this.videoElement);
    }

    get isCallButtonDisabled(): boolean {
        return !this.isInteractionAllowed || this.talkStarted || this.isEditing || this.microphone.getMicrophoneStatus() ||
            (this.avatarEnabled && !this.isShowAvatarEnable);
    }

    async initializeAvatarSession() {
        try {
            this.alert.showLoading();

            if (this.avatar && this.videoElement) {
                this.avatar = null;
                await this.terminateAvatarSession();
            }

            const { avatar, sessionData } = await this.heygen.initializeAvatarSession(this.avatarConfig);
            this.avatar = avatar;
            this.sessionData = sessionData;

            this.heygen.onStreamReady(this.handleStreamReady.bind(this));
            this.heygen.onSpeakCompleted(this.handleSpeakCompleted.bind(this));
            this.heygen.onStreamDisconnected(this.handleStreamDisconnected.bind(this));
        } catch (error) {
            this.alert.showError("Error", "Error initializing avatar session")
            .then((result: { isConfirmed: any; }) => {
                if (result.isConfirmed) {
                    this.event.setShowAvatarEnable(false);
                }
            });
        }
    }

    // Handle when avatar stream is ready
    async handleStreamReady(event: any) {
        if (event.detail && this.videoElement) {
            this.videoElement.srcObject = event.detail;

            this.videoElement.onloadedmetadata = () => {
                this.videoElement.play().then(async () => {
                    this.alert.close(); // Close loading indicator when video is ready to play
                    if (this.isMobile()) {
                        await this.microphone.onMicrophoneClick(this.changeDetector, true);
                    }
                }).catch(error => {
                    console.error("Error playing video:", error);
                    this.alert.showError("Error", "Error playing video");
                });
            };

            this.videoElement.onerror = (error) => {
                console.error("Video element error:", error);
                this.alert.showError("Error", "Video element error");
            };
        } else {
            await this.alert.showError("Error", "Stream is not available");
        }
    }

    async handleSpeakCompleted(event: any) {
        this.heygen.isAvatarSpeaking.next(false);
        console.log("Avatar finish with speaking", event);

        // Start microphone again after avatar stops talking
        if (this.talkStarted) {
             this.microphone.setMicrophoneTalkStatus(false);
             await this.microphone.onMicrophoneClick(this.changeDetector, true);
        }
    }

    handleStreamDisconnected() {
        console.log("Stream disconnected");

        /*if (!this.isLanguageChange) {
            console.log('gotovo')
            this.event.setShowAvatarEnable(false);
        } else {
            this.isLanguageChange = false;
        }

        console.log("Stream disconnected");

        // Turn off microphone if avatar doesn't work
        if (this.micActive) {
            await this.onMicrophoneClick();
        }*/

        if (this.videoElement) {
            this.videoElement.srcObject = null;
            //this.event.setShowAvatarEnable(false);
        }
    }

    private isMobile(): boolean {
        return window.innerWidth <= 992;
    }

    async terminateAvatarSession() {
        await this.heygen.terminateAvatarSession(this.videoElement);
    }

    async startAvatarCall() {
        this.event.setStartedTalk(true);
        this.visibility.showComponent('avatar-conversation');
        this.event.scrollToBottomEvent.emit();
        await this.onMicrophoneClick(true)
    }

    async onMicrophoneClick(micState: boolean) {
        this.microphone.setRobotTalkingStatus(micState);
        await this.microphone.onMicrophoneClick(this.changeDetector, micState);
    }

    async ngOnDestroy() {
        if (this.avatarConfigRequestSubscription){ this.avatarConfigRequestSubscription.unsubscribe(); }
        if (this.talkSubscription){ this.talkSubscription.unsubscribe(); }
        if (this.startMobileStreamSubscription){ this.startMobileStreamSubscription.unsubscribe(); }
        if (this.showAvatarEnableSubscription){ this.showAvatarEnableSubscription.unsubscribe(); }
        if (this.characterImageSubscription){ this.characterImageSubscription.unsubscribe(); }
        if (this.avatarSpeakingTextSubscription){ this.avatarSpeakingTextSubscription.unsubscribe(); }
        if (this.avatarSpeakingModeSubscription){ this.avatarSpeakingModeSubscription.unsubscribe(); }
        if (this.interactionSubscription){ this.interactionSubscription.unsubscribe(); }
        if (this.editSubscription){ this.editSubscription.unsubscribe(); }
        await this.terminateAvatarSession();
    }
}
