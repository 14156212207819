import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import { Subscription } from "rxjs";

import { MessageService } from "../../../core/services/message.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { LanguageService } from "../../../core/services/language.service";
import { ConfigService } from "../../../core/services/config.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { EventService } from "../../../core/services/event.service";
import { ConversationService } from "../../../core/services/conversation.service";
import { Conversation, ConversationMessage } from "../../../core/models/conversation";
import { AlertService } from "../../../core/services/alert.service";
import { WebSocketMessage } from "../../../core/models/webSocketMessage";

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrl: './sidenav.component.scss'
})
export class SidenavComponent implements OnInit, OnDestroy {
    @Input() sidenav!: MatSidenav;

    chatHistoryTitle = 'Chat History';
    chatHistoryIcon = 'assets/icons/close-chat-history.svg';
    deleteIcon = 'assets/icons/delete.svg';
    chatHistoryTooltip: string = 'Close Chat History';
    noChatHistory: string = 'No Previous Chats'
    isInteractionAllowed: boolean = false;
    talkStarted: boolean = false;
    conversations: Conversation[] = [];
    currentConversationId: string | null = null;

    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    talkSubscription!: Subscription;
    conversationsSubscripiton!: Subscription;
    newConversationSubscription!: Subscription;
    currentConversationSubscription!: Subscription;

    constructor(private message: MessageService,
                private visibility: VisibilityService,
                private language: LanguageService,
                private config: ConfigService,
                private interaction: InteractionService,
                private conversation: ConversationService,
                private alert: AlertService,
                private event: EventService) { }

    async ngOnInit() {
        await this.getConversationHistory();

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.chatHistoryTitle = translate.typography.chatHistoryTitle;
            this.chatHistoryTooltip = translate.typography.closeChatHistoryTooltip;
            this.noChatHistory = translate.typography.noChatHistory
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.conversationsSubscripiton = this.conversation.getConversations().subscribe(conversations => {
            this.conversations = conversations;
        });

        this.newConversationSubscription = this.event.getNewConversationStarted().subscribe(isNewConversation => {
            if (isNewConversation) {
                this.getConversationHistory();
            }
        });

        this.currentConversationSubscription = this.conversation.getCurrentConversation().subscribe(conversationId => {
            this.currentConversationId = conversationId;
        });
    }

    // getters
    async getConversationHistory() {
        const response = await this.conversation.getConversationsHistory();

        if (response.status !== 200) {
            await this.alert.showError('Error', 'Error getting the conversation history. Please try again.');
            return;
        } else {
            const validConversations = response.body.filter(conversation => Object.keys(conversation).length > 0); // Remove empty objects from array
            this.conversation.setConversations(validConversations);
        }
    }

    async getConversationMessages(conversationId: string) {
        const messagesResponse = await this.conversation.getConversationMessages(conversationId);

        if (messagesResponse.status !== 200) {
            await this.alert.showError('Error', 'Error getting the messages of conversation. Please try again.');
            return;
        }

        if (messagesResponse.body.questions) {
            return messagesResponse.body.questions;

        }

        return null;
    }

    get isHistoryExists(): boolean {
        return this.conversations.length > 0;
    }

    createHistoryMessageDisplay(author: string, id: string, content: string, conversationId: string, questionId: string, language: string) {
        const historyMessage: WebSocketMessage = {
            id: id,
            type: "text",
            query: content,
            conversationId: conversationId,
            questionId: questionId,
            formattedContent: [],
            language: language,
            token: null,
            device: "",
            documentAnalysisId: null,
            attachments: []
        }

        this.message.setMessage(author, historyMessage);
    }

    async handleHistorycalMessage(conversationId: string) {
        this.conversation.setCurrentConversation(conversationId); // Set current conversation
        const messages = await this.getConversationMessages(conversationId);

        if (messages) {
            this.message.clearMessages(); // Delete messages on FE

            messages.forEach((message: ConversationMessage) => {
                this.createHistoryMessageDisplay("client", message.questionUuid, message.question, conversationId, message.questionUuid, message.language);

                message.answers.forEach(messageAnswer => {
                    this.createHistoryMessageDisplay("ai", messageAnswer.answerId, messageAnswer.answer, conversationId, message.questionUuid, message.language);
                });
            });

            this.visibility.showComponent('avatar-conversation');
            this.event.scrollToBottomEvent.emit();
        }

        if (this.config.getDevice() === 'mobile') {
            await this.sidenav.close(); // close chat history after choose list item
        }
    }

    async deleteConversation(conversationId: string, event: Event) {
        event.stopPropagation();

        const deleteResponse = await this.conversation.deleteConversation(conversationId);

        if (deleteResponse.status !== 200) {
            await this.alert.showError('Error', 'Error deleting conversation. Please try again.');
            return;
        }

        await this.getConversationHistory();

        if (this.currentConversationId === conversationId) { // When delete current conversation
            this.conversation.setCurrentConversation(null);
            this.message.clearMessages();
            this.visibility.hideComponent('avatar-conversation');
        }
    }

    ngOnDestroy(): void {
        this.translateSubscription.unsubscribe();
        this.interactionSubscription.unsubscribe();
        this.talkSubscription.unsubscribe();
        this.conversationsSubscripiton.unsubscribe();
        this.newConversationSubscription.unsubscribe();
        this.currentConversationSubscription.unsubscribe();
    }
}
