import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-close',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M27 5L5 27" stroke="#FBFBFB" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 5L27 27" stroke="#FBFBFB" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class CloseComponent {
    @Input() size: string = 'var(--svg-size)';
}
