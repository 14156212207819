import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessageService } from "../../../core/services/message.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";
import { MatSidenav } from "@angular/material/sidenav";
import { ConfigService } from "../../../core/services/config.service";
import { DocumentAnalysisService } from "../../../core/services/document-analysis.service";
import { AnalyzingDocument } from "../../../core/models/analyzingDocument";
import { ConversationService } from "../../../core/services/conversation.service";
import { AuthService } from "../../../core/services/auth.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() sidenav!: MatSidenav;
    companyName: string = 'ExaPro';
    newChatButton: string = 'New Chat';
    documentAnalysisButton: string = 'Document Analysis';
    learningHubButton: string = 'Learning Hubb';
    chatHistoryTooltip: string = 'Open Chat History';

    talkStarted!: boolean;
    isInteractionAllowed!: boolean;
    isdropdownDocumentAnalysisOpen: boolean = false;
    selectedDocument: AnalyzingDocument | null = null;

    talkSubscription!: Subscription;
    interactionSubscription!: Subscription;
    translateSubscription!: Subscription;
    documentSubscripiton!: Subscription;

    constructor(
        private message: MessageService,
        private visibility: VisibilityService,
        private interaction: InteractionService,
        private event: EventService,
        private language: LanguageService,
        private documentAnalysis: DocumentAnalysisService,
        private conversation: ConversationService,
        private auth: AuthService,
        protected config: ConfigService) {}

    ngOnInit(): void {
        this.translateSubscription = this.language.selectedLanguage$.subscribe(
            (selectedLanguage) => {
                const translate = this.language.getDesignTranslation(
                    selectedLanguage.locale
                );
                this.newChatButton = translate.typography.newChat;
                this.documentAnalysisButton =
                    translate.typography.documentAnalysisButton;
                this.learningHubButton = translate.typography.learningHubButton;
                this.chatHistoryTooltip =
                    translate.typography.openChatHistoryTooltip;
            }
        );

        this.interactionSubscription = this.interaction
            .getInteractionMode()
            .subscribe((interactionAllowed) => {
                this.isInteractionAllowed = interactionAllowed;
            });

        this.talkSubscription = this.event
            .getStartedTalk()
            .subscribe((isTalkStarted) => {
                this.talkStarted = isTalkStarted;
            });

        this.documentSubscripiton = this.documentAnalysis
            .getAnalyzingDocument()
            .subscribe((document) => {
                this.selectedDocument = document;
            });
    }

    get isUserLogged(): boolean {
        return this.config.getAuthenticationEnabled() && !!this.auth.getUserId();
    }

    async onOpenSidenav() {
        await this.sidenav.open();
    }

    async newChat() {
        this.conversation.setCurrentConversation(null); // Set current conversation like unknown

        this.message.clearMessages();
        this.visibility.hideComponent('avatar-conversation');
    }

    learningHub() {
        const httpScheme = this.config.getHttpScheme();
        const adminPanelUrl = this.config.getAdminPanelUrl();
        window.open(`${httpScheme}://${adminPanelUrl}`, '_blank');
    }

    toggledocumentAnalysis() {
        this.isdropdownDocumentAnalysisOpen =
            !this.isdropdownDocumentAnalysisOpen;
    }

    closeDocumentAnalysis() {
        this.isdropdownDocumentAnalysisOpen = false;
    }

    ngOnDestroy(): void {
        if (this.talkSubscription) {
            this.talkSubscription.unsubscribe();
        }
        if (this.interactionSubscription) {
            this.interactionSubscription.unsubscribe();
        }
        if (this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
        if (this.documentSubscripiton) {
            this.documentSubscripiton.unsubscribe();
        }
    }
}
