<div *ngIf="isVisible" class="box-conversation">
    <div class="conversation-container">
        <div class="conversation-wrapper">
            <app-message></app-message>
        </div>
        <app-loader></app-loader>
        <div *ngIf="!isLoadingShown && selectedDocumentToAnalyse && !talkStarted" class="document-container">
            <p class="document-text">{{ analyzingDocumentLabel }} <b>{{ getAnalyzingDocumentName() }}</b></p>
            <div class="document-div">{{ documentAnalysisModeLabel }} <b>{{ activeLabel }}</b></div>
        </div>
    </div>
</div>
