<div [ngClass]="{'talk-started': talkStarted}">
    <div class="bar-container">
        <div #soundWaveRender id="id-bar-container" class="bar-wrapper">
            <div *ngFor="let bar of soundWaveList$ | async" [style.height.%]="bar" class="bar">
                <div class="blue-bar"></div>
                <div class="blank-bar"></div>
            </div>
        </div>
    </div>
</div>
