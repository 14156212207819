import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from "rxjs";

import { WebSocketMessage } from "../../../core/models/webSocketMessage";
import { WebSocketService } from "../../../core/services/web-socket.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { MessageService } from "../../../core/services/message.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { LanguageService } from "../../../core/services/language.service";
import { AudioService } from "../../../core/services/audio.service";
import { EventService } from "../../../core/services/event.service";
import { AnimationService } from "../../../core/services/animation.service";
import { MicrophoneService } from "../../../core/services/microphone.service";
import { ConfigService } from "../../../core/services/config.service";
import { HeyGenService } from "../../../core/services/heygen.service";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../core/services/alert.service";
import { RouterService } from "../../../core/services/router.service";
import { DocumentAnalysisService } from "../../../core/services/document-analysis.service";
import { AnalyzingDocument } from "../../../core/models/analyzingDocument";

@Component({
    selector: 'app-conversation',
    templateUrl: './conversation.component.html',
    styleUrl: './conversation.component.scss'
})
export class ConversationComponent implements OnInit, OnDestroy {
    talkStarted: boolean = false;
    isVisible: boolean = true;
    queueResponseMessage!: WebSocketMessage;
    selectedDocumentToAnalyse: AnalyzingDocument | null = null;
    isLoadingShown: boolean = false;
    activeLabel: string = '';
    documentAnalysisModeLabel: string = '';
    analyzingDocumentLabel: string = ''

    visibilitySubscription!: Subscription;
    socketMessageSubscription!: Subscription
    audioLoadedSubscription!: Subscription;
    talkSubscription!: Subscription;
    documentSubscripiton!: Subscription;
    translateSubscription!: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private event: EventService,
        private config: ConfigService,
        private audio: AudioService,
        private socket: WebSocketService,
        private visibility: VisibilityService,
        private message: MessageService,
        private interaction: InteractionService,
        private language: LanguageService,
        private animation: AnimationService,
        private microphone: MicrophoneService,
        private heygen: HeyGenService,
        private auth: AuthService,
        private alert: AlertService,
        private documentAnalysis: DocumentAnalysisService,
        private router: RouterService) { }

    ngOnInit(): void {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-conversation').subscribe(visible => {
            this.isVisible = visible;

            if (!this.isVisible) {
                this.visibility.showComponent('avatar-introduction');
            } else {
                this.visibility.hideComponent('avatar-introduction');
            }
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.socketMessageSubscription = this.socket.message
        .subscribe(async message => {
            if (message.type === "transcription") {
                this.message.setMessage("client", message);
            } else if (message.type === "error") {
                this.message.setMessage("ai", message);
                this.animation.startDefaultAnimation();
                // If the message is an error and call is started, then we need to start mic again
                // But if the avatar enabled, avatar will say this error message and again start mic by itself
                if (!this.config.getAvatarEnabled() && this.talkStarted && !this.microphone.getMicrophoneTalkStatus()) {
                    this.microphone.setMicrophoneTalkStatus(false);
                    await this.microphone.onMicrophoneClick(this.changeDetector, true);
                }
            } else if (message.type === "audio") {
                this.setQueueMessage(message);
                if (message.audioAnswer && this.microphone.getRobotTalkingStatus() && !this.config.getAvatarEnabled()) {
                    // call
                    this.audio.setupAudio(message.audioAnswer);
                    this.audio.playAudio();
                } else {
                    // voice
                    this.message.setMessage("ai", message);
                    this.animation.startDefaultAnimation();
                }
            } else if (message.type === "text") {
                this.message.setMessage("ai", message);
                this.animation.startDefaultAnimation();
            } else if (message.type === "related_questions") {
                if (message.questionList) {
                    console.log(message.questionList);
                    this.message.setMessageRelatedQuestion(message.questionList);
                } else {
                    this.message.setMessageRelatedQuestion([]);
                }
            } else if (!message.type && message.language && this.language.getSelectedLanguage().locale !== message.language) {
                // Detecting switching language from websocket message with unknown type
                this.language.setLanguage(message.language);

                // if language switch, then we need to start mic again
                if (this.talkStarted && !this.microphone.getMicrophoneTalkStatus()) {
                    this.microphone.setMicrophoneTalkStatus(false);
                    await this.microphone.onMicrophoneClick(this.changeDetector, true);
                }
            } else if (message.type === "unauthorized") {
                if (this.talkStarted) { // Go to sign in and stop the call
                    this.event.setStartedTalk(false);
                }
                if (this.microphone.getMicrophoneTalkStatus()) { // Go to sign in and stop the mic
                    this.microphone.setMicrophoneTalkStatus(true);
                    await this.microphone.onMicrophoneClick(this.changeDetector, false);
                }
                await this.logout();
            }
        });

        this.audioLoadedSubscription = this.audio.onAudioLoaded.subscribe((loaded)=> {
            if (loaded && this.getQueueMessage() !== undefined) { this.message.setMessage("ai", this.getQueueMessage()); }
        });

        combineLatest([this.audio.isAudioPlaying, this.heygen.isAvatarSpeaking, this.visibility.getVisibility('avatar-loader')]).subscribe(([
            isAudioPlaying, isAvatarSpeaking, isLoadingShown]) => {
            if (!this.config.getAvatarEnabled()) {
                this.interaction.setInteractionMode(!(isAudioPlaying.isPlaying || isLoadingShown));
            } else {
                if (this.talkStarted) {
                    this.interaction.setInteractionMode(!isLoadingShown); // If call is started, then we can stop avatar speech
                } else {
                    this.interaction.setInteractionMode(!(isAvatarSpeaking || isLoadingShown)); // If call is not started, then we can't stop avatar speech
                }
            }
        });

        this.documentSubscripiton = this.documentAnalysis.getAnalyzingDocument().subscribe(document => {
            this.selectedDocumentToAnalyse = document;
        });

        this.visibility.getVisibility('avatar-loader').subscribe(async loading => {
            this.isLoadingShown = loading;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.activeLabel = translate.typography.activeLabel;
            this.documentAnalysisModeLabel = translate.typography.documentAnalysisModeLabel;
            this.analyzingDocumentLabel = translate.typography.analyzingDocumentLabel;
        });

        this.event.scrollToBottomEvent.emit();
    }

    // getters
    getQueueMessage (): WebSocketMessage {
        return this.queueResponseMessage;
    }

    getAnalyzingDocumentName() {
        return this.selectedDocumentToAnalyse?.displayName + '.' + this.selectedDocumentToAnalyse?.extension;
    }

    // setters
    setQueueMessage (message: WebSocketMessage) {
        this.queueResponseMessage = message;
    }

    async logout() {
        this.alert.showLoading();
        this.animation.destroyAnimationApp();
        await this.auth.logout();
        this.router.goToSignIn();
        this.alert.close();
    }

    ngOnDestroy () {
        if (this.visibilitySubscription) { this.visibilitySubscription.unsubscribe(); }
        if (this.socketMessageSubscription) { this.socketMessageSubscription.unsubscribe(); }
        if (this.audioLoadedSubscription) { this.audioLoadedSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.documentSubscripiton) { this.documentSubscripiton.unsubscribe(); }
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    }
}
