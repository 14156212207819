import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-call',
    template: `
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M0 4.6637C0 9.75544 6.75225 16.5 11.8381 16.5C12.9513 16.5 13.9911 16.0801 14.7576 15.3137L15.4242 14.5473C16.1974 13.7742 16.1974 12.4679 15.3909 11.6615C15.3709 11.6415 13.7645 10.4086 13.7645 10.4086C12.9646 9.64881 11.7048 9.64881 10.9116 10.4086L9.93841 11.1883C7.80542 10.2819 6.29232 8.76242 5.31915 6.55644L6.09236 5.58341C6.8589 4.79033 6.8589 3.52406 6.09236 2.73097C6.09236 2.73097 4.85922 1.1248 4.83922 1.10481C4.03269 0.298396 2.72623 0.298396 1.91969 1.10481L1.2198 1.71129C0.419932 2.50437 0 3.54405 0 4.65703V4.6637Z" fill="#EBF3FF"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-width-size);
            height: var(--svg-height-size);
        }
    `]
})
export class CallComponent {
    @Input() heightSize: string = 'var(--svg-height-size)';
    @Input() widthSize: string = 'var(--svg-width-size)';
}
