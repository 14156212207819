<div class="box-character" [ngClass]="{'talk-started': talkStarted}">
    <div class="character-container">
        <div class="character">
            <div class="character-inside">
                <div *ngIf="!avatarEnabled" class="robot-wrapper">
                    <div class="character-bubble">
                        <p class="bubble-text">{{ bubbleText[0] }}</p>
                        <p class="bubble-text">{{ bubbleText[1] }}</p>
                    </div>
                    <button id="animationContainer" [disabled]="isStartCallButtonDisabled" class="start-call-button"
                            (click)="startRobotTalk()"></button>
                    <div class="character-shadow"></div>
                </div>
                <div *ngIf="avatarEnabled" class="avatar-container">
                    <div id="id-start-heygen-call-button" class="start-heygen-call-button">
                        <app-hey-gen-avatar></app-hey-gen-avatar>
                    </div>
                </div>
                <div *ngIf="talkStarted" class="soundwave-container" [ngStyle]="{'visibility': microphone.getMicrophoneTalkStatus() ? 'visible' : 'hidden'}">
                    <app-soundwave></app-soundwave>
                </div>
            </div>
            <div class="character-buttons">
                <button [disabled]="!isInteractionAllowed" id="id-close-call" class="end-talk-button" type="button" (click)="endTalk()">
                    <app-close></app-close>
                </button>
                <button [disabled]="isStopButtonDisabled" id="id-restart-call" class="restart-talk-button" type="button" (click)="interactCharacter()">
                    <app-stop></app-stop>
                </button>
            </div>
        </div>

        <div *ngIf="avatarEnabled && !showAvatarEnable" class="enable-avatar-container">
            <div class="enable-header">{{ enableAvatarText }}</div>
            <div class="enable-body">
                <p>{{ clickText }}</p>
                <button id="start-avatar" class="enable-avatar" (click)="enableAvatar()">{{ okButton }}</button>
            </div>
        </div>
    </div>
</div>

