import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { Attachment } from "../../../core/models/webSocketMessage";
import { EventService } from "../../../core/services/event.service";
import { MultimediaService } from "../../../core/services/multimedia.service";
import { ConfigService } from "../../../core/services/config.service";

@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  styleUrl: './related-content.component.scss'
})
export class RelatedContentComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() attachments: Attachment[] = [];
    @Input() author!: string;

    relatedContentTitle: string = 'Related Content:';

    translateSubscription!: Subscription;

    imageWidth = 16.7; // Image width + margin (200px + 20px margin) // 240px
    imageWidthMobile = 75;
    visibleCount = 1;
    currentIndex = 0;

    constructor(
        private language: LanguageService,
        private event: EventService,
        private config: ConfigService,
        private multimedia: MultimediaService) { }

    async ngOnInit() {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.relatedContentTitle = translate.typography.relatedContentTitle;
        });
    }

    ngAfterViewInit() {
        this.calculateVisibleCount();
    }

    calculateVisibleCount() {
        const container = document.getElementById('media-buttons-container');
        if (container) {
            const containerWidthVW = (container.offsetWidth / window.innerWidth) * 100;

            if (this.config.isMobileDevice()) {
                this.visibleCount = Math.floor(containerWidthVW / this.imageWidthMobile);
            } else {
                this.visibleCount = Math.floor(containerWidthVW / this.imageWidth);
            }
            this.currentIndex = Math.min(this.currentIndex, this.maxOffset);
        }
    }

    backContent() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        }
    }

    nextContent() {
        if (this.currentIndex < this.maxOffset) {
            this.currentIndex++;
        }
    }

    get maxOffset() {
        return Math.max(this.attachments.length - this.visibleCount, 0);
    }

    get transform() {
        if (this.config.isMobileDevice()) {
            return `translateX(${-this.currentIndex * this.imageWidthMobile}vw)`;
        } else {
            return `translateX(${-this.currentIndex * this.imageWidth}vw)`;
        }
    }

    getAttachmentExtension(attachment: Attachment): string | undefined {
        if (attachment?.name) {
            const parts = attachment.name.split('.');
            return parts.length > 1 ? parts.pop()?.toUpperCase() : undefined;
        }
        return undefined;
    }

    emitMediaType(mediaType: string, source: string) {
        this.multimedia.emitMediaData(mediaType, source);
        this.event.blurEfectEvent.emit(true);
    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    }
}
