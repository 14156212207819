import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { combineLatest, Subscription } from "rxjs";

import { InteractionService } from "../../../core/services/interaction.service";
import { LanguageService } from "../../../core/services/language.service";
import { MultimediaService } from "../../../core/services/multimedia.service";
import { MicrophoneService } from "../../../core/services/microphone.service";
import { MessageService } from "../../../core/services/message.service";
import { AttachmentDto } from "../../../core/dtos/attachmentDto";
import { EventService } from "../../../core/services/event.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { AnimationService } from "../../../core/services/animation.service";
import { AudioService } from "../../../core/services/audio.service";
import { AlertService } from "../../../core/services/alert.service";
import { ConfigService } from "../../../core/services/config.service";

@Component({
  selector: 'app-conversation-controls',
  templateUrl: './conversation-controls.component.html',
  styleUrl: './conversation-controls.component.scss'
})
export class ConversationControlsComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
    @ViewChild('textarea') textareaRef!: ElementRef<HTMLTextAreaElement>;

    currentInputPlaceholder: string = 'Hold the mic to talk or type a question...';
    robotIcon: string = '';

    aiErrorMessage: string = 'ExaAI aims for accuracy, but errors can occur. Confirm key details.'

    currentInputValue!: string;
    audioId!: string;
    isInteractionAllowed: boolean = false;
    isSoundwaveShown: boolean = true;
    talkStarted!: boolean;
    isInputShown: boolean = true;
    files: AttachmentDto[] = [];

    visibilitySoundwaveSubscription!: Subscription;
    visibilityInputSubscription!: Subscription;
    closeMicrophoneSubscription!: Subscription;
    closeMicrophoneSubscriptionTalk!: Subscription
    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    startMicrophoneSubscription!: Subscription;
    talkSubscription!: Subscription;
    characterIconSubscription!: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        protected microphone: MicrophoneService,
        private multimedia: MultimediaService,
        private message: MessageService,
        private language: LanguageService,
        private interaction: InteractionService,
        private event: EventService,
        private visibility: VisibilityService,
        private animation: AnimationService,
        private audio: AudioService,
        private alert: AlertService,
        private config: ConfigService
    ) { }

    ngOnInit() {
        this.visibilitySoundwaveSubscription = this.visibility.getVisibility('avatar-soundwave').subscribe(visible => {
            this.isSoundwaveShown = visible;
            if (visible) { this.visibility.hideComponent('avatar-input') }
        });
        this.visibilityInputSubscription = this.visibility.getVisibility('avatar-input').subscribe(visible => {
            this.isInputShown = visible;
            if (visible) { this.visibility.hideComponent('avatar-soundwave'); }
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.currentInputPlaceholder =  translate.typography.currentInputPlaceholder;
            this.aiErrorMessage = translate.typography.aiErrorMessage;
        });

        this.characterIconSubscription = this.config.getCharacterIcon().subscribe(icon => {
            this.robotIcon = icon;
        });

        this.closeMicrophoneSubscription = this.microphone.closeMicrophoneEvent
        .subscribe(async audioFile => {
            if (this.microphone.getRobotTalkingStatus()) {
                await this.onMicrophoneClick();
            } else {
                if (this.talkStarted) {
                    this.talkStarted = false;
                }
                await this.microphone.onMicrophoneClick(this.changeDetector, false);
            }

            if (audioFile.size === 0) {
                console.log('The audio message is empty');
                return;
            }

            try {
                this.visibility.showComponent('avatar-loader');

                const audio = await this.message.submitAudioData(audioFile);
                // @ts-ignore
                this.setAudioId(audio.body.id);

                if (this.talkStarted) {
                    await this.message.createConversationRequest("audio", "call", this.getCurrentInputValue(), this.getAudioId(), this.files);
                } else {
                    await this.message.createConversationRequest("audio", "voice", this.getCurrentInputValue(), this.getAudioId(), this.files);
                }

                this.removeFiles();
            } catch (e: any) {
            }
        });

        // Start mic again when avatar disabled, after receiving a response
        this.audio.isAudioPlaying.subscribe(async (status) => {
            if (!status.isPlaying && this.talkStarted && !status.isBeep) {
                this.microphone.setMicrophoneTalkStatus(false);
                await this.microphone.onMicrophoneClick(this.changeDetector, true);
            }
        });

        combineLatest([
            this.event.getStartedTextConversation(),
            this.event.getStartedVoiceConversation(),
            this.visibility.getVisibility('avatar-loader'),
            this.audio.isAudioPlaying])
            .subscribe(([switchActionState, onMicrophoneClickState, LoadingState, AudioState]) => {
                if (switchActionState || onMicrophoneClickState) {
                    this.animation.startAnimation('listening');
                } else if (LoadingState) {
                    this.animation.startAnimation('loading');
                } else if (AudioState.isPlaying) {
                    this.animation.startAnimation('speaking');
                } else {
                    this.animation.startDefaultAnimation();
                }
            }
        );

        this.visibility.showComponent('avatar-input')
    }

    // getters
    getAudioId (): string {
        return this.audioId;
    }

    getCurrentInputValue (): string {
        return this.currentInputValue;
    }

    getMicState(): boolean {
        console.log('TALK STARTED: ' + this.talkStarted);
        console.log('MICROPHONE TALK STATUS: ' + this.microphone.getMicrophoneTalkStatus());
        console.log('MICROPHONE STATUS: ' + this.microphone.getMicrophoneStatus());

        const talkMicStatus = this.microphone.getMicrophoneTalkStatus();
        const voiceMicStatus = this.microphone.getMicrophoneStatus();

        return (this.talkStarted && !talkMicStatus) || (!this.talkStarted && !voiceMicStatus);
    }

    // setters
    setAudioId (id: string) {
        this.audioId = id;
    }

    // events
    async startTalkMobile() {
        this.visibility.showComponent('avatar-conversation');
        this.event.setStartedTalk(true);
        this.microphone.setRobotTalkingStatus(true);

        if (!this.config.getAvatarEnabled()) {
            this.audio.playBeepAudio();
            await this.microphone.onMicrophoneClick(this.changeDetector, true);
        }
    }

    async onMicrophoneClick(flag: any = "") {
        console.log(flag)
        const mics = this.getMicState();
        await this.microphone.onMicrophoneClick(this.changeDetector, mics);
    }

    onOpenAttachments() {
        this.fileInputRef.nativeElement.click();
    }

    hasUploadFiles() {
        return this.files.length > 0;
    }

    async onFileSelect(event: any) {
        const maxSize = 50 * 1024 * 1024; // 50 MB
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size <= maxSize) {
                this.handleDroppedFiles(file);
            } else {
                await this.alert.showError('Error', `File exceeds the 50MB size limit and will not be uploaded.`)
            }
        }

        this.fileInputRef.nativeElement.value = '';
    }

    handleDroppedFiles(file: File | {}) {
        if (file instanceof File) {
            const type = this.multimedia.getAttachmentType(file.name);
            const url = URL.createObjectURL(file);

            // We need to make file with lowercase bcs of BE restrictions
            const sanitizedFileName = file.name.replace(/\s+/g, '_');
            const fileLowerCaseSanitezed = new File([file], sanitizedFileName.toLowerCase(), { type: file.type });

            // Prepare attachments to display into conversation control input
            const fileDto = new AttachmentDto(file.name, file.size, url, type, fileLowerCaseSanitezed);
            this.files.push(fileDto);
        }
    }

    getExtension(fileName: string) {
        return fileName.split('.').pop()!.toUpperCase();
    }

    async onSubmitMessage() {
        if (this.textareaRef && this.textareaRef.nativeElement) {
            this.textareaRef.nativeElement.focus();
        }

        const input = this.getCurrentInputValue();

        if (this.files && this.files.length > 0) {
            this.alert.showLoading();
        }

        await this.message.createConversationRequest("text", "text", input, '', this.files);

        this.alert.close();

        this.resetTextarea();
        this.removeFiles();
    }

    getAttachmentExtension(file: AttachmentDto): string | undefined {
        if (file?.name) {
            const parts = file.name.split('.');
            return parts.length > 1 ? parts.pop()?.toUpperCase() : undefined;
        }
        return undefined;
    }

    onInput(): void {
        this.event.scrollToBottomEvent.emit();
        const textareaElem = this.textareaRef.nativeElement;
        textareaElem.style.height = '2rem';

        const scHeightPx = textareaElem.scrollHeight;
        const remSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const scHeightRem = scHeightPx / remSize;

        textareaElem.style.height = `${scHeightRem}rem`;
    }

    isInputFilled() {
        const input = this.getCurrentInputValue();
        return input !== undefined && input.trim() !== '';
    }

    async onEnterKey(event: any) {
        if (this.isInputFilled() || this.hasUploadFiles()) {
            await this.onSubmitMessage();
        } else {
            event.preventDefault();
        }
    }

    switchAction(): boolean {
        const canSendMessage = this.isInputFilled() || this.hasUploadFiles();
        this.event.setStartedTextConversation(canSendMessage);
        return canSendMessage;
    }

    resetTextarea() {
        this.currentInputValue = "";
        const textareaElem = this.textareaRef.nativeElement;
        textareaElem.style.height = '2rem';
    }

    removeFiles() {
        this.files = [];
    }

    removeFile(index: number) {
        this.files.splice(index, 1);
    }

    ngOnDestroy() {
        if (this.visibilitySoundwaveSubscription) { this.visibilitySoundwaveSubscription.unsubscribe(); }
        if (this.visibilityInputSubscription) { this.visibilityInputSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.closeMicrophoneSubscription) { this.closeMicrophoneSubscription.unsubscribe(); }
        if (this.startMicrophoneSubscription) { this.startMicrophoneSubscription.unsubscribe(); }
        if (this.closeMicrophoneSubscriptionTalk) { this.closeMicrophoneSubscriptionTalk.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.characterIconSubscription) { this.characterIconSubscription.unsubscribe(); }
    }
}
