import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-download',
    template: `
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.875 10.6299H10.5C10.1548 10.6299 9.875 10.3501 9.875 10.0049C9.875 9.6597 10.1548 9.37988 10.5 9.37988H12.875C13.9089 9.37988 14.75 8.53876 14.75 7.50488C14.75 6.43595 13.9977 5.62988 13 5.62988C12.6548 5.62988 12.375 5.35007 12.375 5.00488C12.375 3.97101 11.5339 3.12988 10.5 3.12988C10.2973 3.12988 10.0906 3.16891 9.86794 3.2492C9.61731 3.3396 9.33703 3.26123 9.16966 3.05398C8.567 2.30785 7.68506 1.87988 6.75 1.87988C5.02684 1.87988 3.625 3.28176 3.625 5.00488C3.625 5.35007 3.34519 5.62988 3 5.62988C2.00234 5.62988 1.25 6.43595 1.25 7.50488C1.25 8.53876 2.09113 9.37988 3.125 9.37988H5.5C5.84519 9.37988 6.125 9.6597 6.125 10.0049C6.125 10.3501 5.84519 10.6299 5.5 10.6299H3.125C1.40184 10.6299 0 9.22801 0 7.50488C0 6.67026 0.297375 5.89123 0.837281 5.31123C1.26216 4.85485 1.80772 4.55426 2.41178 4.43623C2.69147 2.29163 4.53038 0.629883 6.75 0.629883C7.92488 0.629883 9.03728 1.10698 9.86094 1.95179C10.0764 1.90398 10.2901 1.87988 10.5 1.87988C12.0279 1.87988 13.3033 2.98213 13.5726 4.43326C14.1829 4.54913 14.7342 4.85095 15.1627 5.31126C15.7026 5.89123 16 6.67026 16 7.50488C16 9.22801 14.5982 10.6299 12.875 10.6299Z" fill="#31405A"/>
            <path d="M12.875 10.6299H10.5C10.1548 10.6299 9.875 10.3501 9.875 10.0049C9.875 9.6597 10.1548 9.37988 10.5 9.37988H12.875C13.9089 9.37988 14.75 8.53876 14.75 7.50488C14.75 6.43595 13.9977 5.62988 13 5.62988C12.6548 5.62988 12.375 5.35007 12.375 5.00488C12.375 3.97101 11.5339 3.12988 10.5 3.12988C10.2973 3.12988 10.0906 3.16891 9.86794 3.2492C9.61731 3.3396 9.33703 3.26123 9.16966 3.05398C8.567 2.30785 7.68506 1.87988 6.75 1.87988C5.02684 1.87988 3.625 3.28176 3.625 5.00488C3.625 5.35007 3.34519 5.62988 3 5.62988C2.00234 5.62988 1.25 6.43595 1.25 7.50488C1.25 8.53876 2.09113 9.37988 3.125 9.37988H5.5C5.84519 9.37988 6.125 9.6597 6.125 10.0049C6.125 10.3501 5.84519 10.6299 5.5 10.6299H3.125C1.40184 10.6299 0 9.22801 0 7.50488C0 6.67026 0.297375 5.89123 0.837281 5.31123C1.26216 4.85485 1.80772 4.55426 2.41178 4.43623C2.69147 2.29163 4.53038 0.629883 6.75 0.629883C7.92488 0.629883 9.03728 1.10698 9.86094 1.95179C10.0764 1.90398 10.2901 1.87988 10.5 1.87988C12.0279 1.87988 13.3033 2.98213 13.5726 4.43326C14.1829 4.54913 14.7342 4.85095 15.1627 5.31126C15.7026 5.89123 16 6.67026 16 7.50488C16 9.22801 14.5982 10.6299 12.875 10.6299Z" fill="#31405A"/>
            <path d="M10.3169 11.6879C10.0728 11.4439 9.67711 11.4439 9.43304 11.6879L8.62498 12.496V6.25488C8.62498 5.9097 8.34517 5.62988 7.99998 5.62988C7.65479 5.62988 7.37498 5.9097 7.37498 6.25488V12.496L6.56695 11.6879C6.32286 11.4439 5.92714 11.4439 5.68307 11.6879C5.43901 11.932 5.43895 12.3278 5.68304 12.5718L7.11607 14.0049C7.35557 14.2444 7.66886 14.3659 7.98342 14.37C7.98898 14.3702 7.99439 14.3709 8.00001 14.3709C8.00564 14.3709 8.01104 14.3702 8.01661 14.37C8.33117 14.3659 8.64445 14.2444 8.88395 14.0049L10.317 12.5718C10.561 12.3278 10.561 11.932 10.3169 11.6879Z" fill="#31405A"/>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class DownloadComponent {
    @Input() size: string = 'var(--svg-size)';
}
