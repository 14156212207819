import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { ConfigService } from "./config.service";
import { HttpService } from "./http.service";
import { AnalyzingDocument } from "../models/analyzingDocument";
import { HttpResponseModel } from "../models/http.model";
import {
    Conversation,
    ConversationMessage,
    ConversationMessageResponse,
    ConversationRequest
} from "../models/conversation";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class ConversationService {
    currentConversationId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    conversations: BehaviorSubject<Conversation[]> = new BehaviorSubject<Conversation[]>([]);

    constructor(private http: HttpService, private config: ConfigService, private auth: AuthService) {}

    // getters
    private getConversationHistoryUrl(): string {
        return `${this.config.getHttpScheme()}://${this.config.getN8nHost()}/webhook/conversation`;
    }

    getCurrentConversation(): Observable<string | null> {
        return this.currentConversationId;
    }

    getConversations(): Observable<Conversation[]> {
        return this.conversations;
    }

    // setters
    setCurrentConversation(conversationId: string | null): void {
        this.currentConversationId.next(conversationId);
    }

    setConversations(conversations: Conversation[]): void {
        this.conversations.next(conversations);
    }

    async getConversationsHistory(): Promise<HttpResponseModel<Conversation[]>> {
        const url = `${this.getConversationHistoryUrl()}/get`;

        try {
            const conversationRequest: ConversationRequest = {
                agentId: this.config.getAgentId(),
                userId: this.auth.getUserId()
            }

            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setContent(conversationRequest)
                .create<Conversation[]>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }

        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: []
            };
        }
    }

    async getConversationMessages(conversationId: string): Promise<HttpResponseModel<ConversationMessageResponse>>{
        const url = `${this.getConversationHistoryUrl()}/messages`;

        try {
            const conversationMessages = {
                conversationId: conversationId
            }

            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setContent(conversationMessages)
                .create<ConversationMessageResponse>()
            );

            return {
                body: response.body,
                status: response.status,
                message: response.message
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: {
                    conversationName: '',
                    conversationUuid: '',
                    questions: []
                }
            };
        }
    }

    async deleteConversation(conversationId: string) {
        const url = `${this.getConversationHistoryUrl()}/delete`;

        try {
            const deleteConversation = {
                conversationId: conversationId
            }

            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setContent(deleteConversation)
                .create()
            );

            return {
                body: response.body,
                status: response.status,
                message: response.message
            };
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: null
            };
        }
    }
}
