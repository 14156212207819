import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-arrow-next',
    template: `
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(180deg);">
            <path d="M9 16L2 9L9 2" [attr.stroke]="stroke" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-arrow-color: #C6D0DA;
        }
        svg path {
            stroke: var(--stroke-arrow-color);
        }
        svg {
            width: var(--svg-width-size);
            height: var(--svg-height-size);
        }
    `]
})
export class ArrowNextComponent {
    @Input() heightSize: string = 'var(--svg-height-size)';
    @Input() widthSize: string = 'var(--svg-width-size)';
    @Input() stroke: string = 'var(--stroke-arrow-color)';
}
