import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { WebSocketService } from '../../../core/services/web-socket.service';

@Component({
    selector: 'app-lost-network',
    templateUrl: './lost-network.component.html',
    styleUrl: './lost-network.component.scss',
})
export class LostNetworkComponent implements OnInit {
    companyLogo: string = 'assets/icons/exafy-logo.svg';
    companyName: string = 'Exafy';
    lostConnectionImage: string = 'assets/images/lostConnection.png';
    lostConnectionName: string = 'Lost Connection!';
    lostConnectionDescription: string = 'You have lost connection with system';

    constructor(protected socket: WebSocketService) {}

    ngOnInit(): void {}

    simulateReconnect() {
        //this.socket.simulateReconnection();
    }
}
