import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { ConfigService } from "./config.service";
import { LoginModel } from "../models/loginModel";
import { UserModel } from "../models/user";
import { HttpService } from "./http.service";
import { v4 as uuidv4 } from "uuid";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class AnonymousService {
    constructor(private auth: AuthService) {}

    async getAnonymousId(): Promise<string> {
        let anonymousId = "";

        if (await this.auth.isLogged()) {
            anonymousId = this.auth.getUserId();
        } else {
            anonymousId = this.generateAnonymousId("anonymousId");
        }

        return anonymousId;
    }

    generateAnonymousId(key: string): string {
        let id = localStorage.getItem(key) ?? uuidv4();
        localStorage.setItem(key, id);
        return id;
    }
}
