import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-learning-hub',
    template: `
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.3281 2.26562H18.9219V1.40625C18.9219 0.931617 18.5371 0.546875 18.0625 0.546875C15.1075 0.546875 12.2114 1.71666 11.1875 2.17629C10.1636 1.71666 7.26742 0.546875 4.3125 0.546875C3.83787 0.546875 3.45312 0.931617 3.45312 1.40625V2.26562H1.04688C0.572242 2.26562 0.1875 2.65037 0.1875 3.125V18.5938C0.1875 19.0684 0.572242 19.4531 1.04688 19.4531H21.3281C21.8028 19.4531 22.1875 19.0684 22.1875 18.5938V3.125C22.1875 2.65037 21.8028 2.26562 21.3281 2.26562ZM12.0469 15.5667V3.67745C12.9522 3.28579 15.0277 2.48356 17.2031 2.30262V14.3285C15.1668 14.4746 13.2444 15.0984 12.0469 15.5667ZM5.17188 2.30258C7.34717 2.48356 9.42273 3.28575 10.3281 3.67741V15.5667C9.13059 15.0983 7.20825 14.4745 5.17188 14.3285V2.30258ZM1.90625 3.98438H3.45312V15.1562C3.45312 15.6309 3.83787 16.0156 4.3125 16.0156C7.48815 16.0156 10.7511 17.6178 10.8034 17.6438C10.9155 17.6998 11.046 17.7334 11.1802 17.7344H1.90625V3.98438ZM20.4688 17.7344H11.1923C11.3271 17.7335 11.4597 17.6995 11.5713 17.6439C11.6039 17.6276 14.8771 16.0156 18.0625 16.0156C18.5371 16.0156 18.9219 15.6309 18.9219 15.1562V3.98438H20.4688V17.7344Z" [attr.fill]="stroke"/>
            <path d="M21.3281 2.26562H18.9219V1.40625C18.9219 0.931617 18.5371 0.546875 18.0625 0.546875C15.1075 0.546875 12.2114 1.71666 11.1875 2.17629C10.1636 1.71666 7.26742 0.546875 4.3125 0.546875C3.83787 0.546875 3.45312 0.931617 3.45312 1.40625V2.26562H1.04688C0.572242 2.26562 0.1875 2.65037 0.1875 3.125V18.5938C0.1875 19.0684 0.572242 19.4531 1.04688 19.4531H21.3281C21.8028 19.4531 22.1875 19.0684 22.1875 18.5938V3.125C22.1875 2.65037 21.8028 2.26562 21.3281 2.26562ZM12.0469 15.5667V3.67745C12.9522 3.28579 15.0277 2.48356 17.2031 2.30262V14.3285C15.1668 14.4746 13.2444 15.0984 12.0469 15.5667ZM5.17188 2.30258C7.34717 2.48356 9.42273 3.28575 10.3281 3.67741V15.5667C9.13059 15.0983 7.20825 14.4745 5.17188 14.3285V2.30258ZM1.90625 3.98438H3.45312V15.1562C3.45312 15.6309 3.83787 16.0156 4.3125 16.0156C7.48815 16.0156 10.7511 17.6178 10.8034 17.6438C10.9155 17.6998 11.046 17.7334 11.1802 17.7344H1.90625V3.98438ZM20.4688 17.7344H11.1923C11.3271 17.7335 11.4597 17.6995 11.5713 17.6439C11.6039 17.6276 14.8771 16.0156 18.0625 16.0156C18.5371 16.0156 18.9219 15.6309 18.9219 15.1562V3.98438H20.4688V17.7344Z" fill="black" fill-opacity="0.2"/>
        </svg>
    `,
    styles: [`
        :host {
            --learning-hub-color: #657A90;
        }
        svg g path {
            stroke: var(--learning-hub-color);
        }
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class LearningHubComponent {
    @Input() stroke: string = 'var(--learning-hub-color)';
    @Input() size: string = 'var(--svg-size)';
}
