export const environment = {
    type: 'staging',
    apiUrl: 'orchestrator-service.test.exafysolutions.ae',
    orchestratorUrl: 'channel-service.test.exafysolutions.ae',
    analyticsUrl: 'analytics-service.test.exafysolutions.ae',
    n8nUrl: 'n8n.test.exafysolutions.ae',
    n8nChannelUrl: 'n8n-channel-service.test.exafysolutions.ae',
    wssScheme: 'wss',
    httpScheme: 'https',
    design: 'design-1',
    adminPanelUrl: 'admin-panel.test.exafysolutions.ae'
};
