import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boldItalicText'
})
export class BoldItalicTextPipe implements PipeTransform {

    transform(value: string): string {
        if (!value) return value;
        // Change **text** with <strong>text</strong>
        value = value.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        // Change *text* with <italic>text</italic>
        value = value.replace(/\*(.*?)\*/g, '<italic>$1</italic>');
        return value;
    }

}
