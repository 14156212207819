import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { VisibilityService } from "../../../core/services/visibility.service";
import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit, OnDestroy {
    companyLogo: string = "assets/icons/logo.svg"

    loadingTexts: string[][] = [];

    currentPhase: string = "";
    currentIndex: number = 0;
    currentTextIndex: number = 0;
    intervalId: any;

    isLoadingShown: boolean = false;

    loadingBarSubscription!: Subscription;
    translateSubscription!: Subscription;

    constructor(private event: EventService,
                private cdr: ChangeDetectorRef,
                private visibility: VisibilityService,
                private language: LanguageService) { }

    ngOnInit(): void {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.loadingTexts = translate.typography.loadingTexts;

            this.loadingBarSubscription = this.visibility.getVisibility('avatar-loader').subscribe(async loading => {
                this.isLoadingShown = loading;

                if (this.isLoadingShown) {
                    this.startLoadingProcess();
                } else {
                    if (this.intervalId) {
                        clearInterval(this.intervalId);
                    }
                }
            });
        });
    }

    startLoadingProcess() {
        this.currentIndex = Math.floor(Math.random() * this.loadingTexts.length);
        this.currentTextIndex = 0;
        this.currentPhase = this.loadingTexts[this.currentIndex][this.currentTextIndex];

        this.intervalId = setInterval(() => {
            this.currentTextIndex++;

            if (this.currentTextIndex < this.loadingTexts[this.currentIndex].length) {
                this.currentPhase = this.loadingTexts[this.currentIndex][this.currentTextIndex];
            } else {
                this.currentPhase = this.loadingTexts[this.currentIndex][this.currentTextIndex - 1];
                clearInterval(this.intervalId);
            }
        }, 2000);
    }



    get phaseLetters(): string[] {
        return this.currentPhase.split('').map(char => char === ' ' ? '\u00A0' : char);
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        if (this.loadingBarSubscription) { this.loadingBarSubscription.unsubscribe(); }
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    }
}
