import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-enter',
    template: `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_95_1290)">
                <path d="M21.5386 10.4609L17.231 22.7686L14.7694 17.2302L9.23096 14.7686L21.5386 10.4609Z" stroke="#FBFBFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.5383 10.4609L14.769 17.2302" stroke="#FBFBFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_95_1290">
                    <rect width="14.7692" height="14.7692" fill="white" transform="translate(8 9.23047)"/>
                </clipPath>
            </defs>
        </svg>
    `,
    styles: [`
        svg {
            width: var(--svg-size);
            height: var(--svg-size);
        }
    `]
})
export class EnterComponent {
    @Input() size: string = 'var(--svg-size)';
}
