import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { AVATAR_CONFIG, LANG_CONFIG } from "../../app.config";
import { HttpService } from "./http.service";
import { AgentModel } from "../models/project";
import { Language } from "../models/language";
import { UserModel } from "../models/user";
import { AvatarConfig, AvatarConfigRequest } from "../models/avatarConfig";
import { env } from "onnxruntime-common";


@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config!: AgentModel
    agentId: string = "";
    languages: Language[] = LANG_CONFIG;
    avatarConfig: AvatarConfig = AVATAR_CONFIG;

    url: string = `${this.getHttpScheme()}://${this.getHost()}/api`;
    avatarConfigRequest: BehaviorSubject<AvatarConfigRequest> = new BehaviorSubject<AvatarConfigRequest>({ name: '', voiceId: '' });
    characterIcon: BehaviorSubject<string> = new BehaviorSubject<string>('');
    characterImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
    avatarGender: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private http: HttpService) {}

    //obrisi
    getUrl() {
        return this.url;
    }

    getToken(){
        return localStorage.getItem("token");
    }

    // getters environment
    getHost () {
        return environment.apiUrl;
    }

    getOrchestrationHost () {
        return environment.orchestratorUrl;
    }

    getAnalyticsHost () {
        return environment.analyticsUrl;
    }

    getN8nHost () {
        return environment.n8nUrl;
    }

    getN8nChannelHost () {
        return environment.n8nChannelUrl;
    }

    getAdminPanelUrl() {
        return environment.adminPanelUrl;
    }

    getDesignType() {
      return environment.design;
    }

    getWssScheme () {
        return environment.wssScheme;
    }

    getHttpScheme () {
        return environment.httpScheme;
    }

    getDevice(): string {
        if (typeof window !== 'undefined' && window.innerWidth) {
            return window.innerWidth <= 992 ? 'mobile' : 'desktop';
        }
        return 'default';
    }

    // getters config
    getLanguages() {
        return this.config.languages;
    }

    getShortcuts(language: string) {
        return this.config.shortcuts
            .filter(s => s.language.locale === language).map(shortcut => ({ text: shortcut.name })) ?? [];
    }

    getProjectName(): string {
        return this.config.name;
    }

    getAgentName(): string {
        return this.config.name;
    }

    getAnalyticEnabled() {
        return this.config.analyticEnabled;
    }

    //getters
    getAgentId(): string {
        return this.agentId;
    }

    getDesignId(): string {
        return '3d41f6bc-ad1e-47fe-822d-c0653c22a3f8';
    }

    getAvatarEnabled(): boolean {
        return this.config.avatarEnabled;
    }

    getAuthenticationEnabled(): boolean {
        return this.config.authenticationEnabled;
    }

    getCharacterIcon(): Observable<string> {
        return this.characterIcon.asObservable();
    }

    getCharacterImage(): Observable<string> {
        return this.characterImage.asObservable();
    }

    getCurrentAvatarGender(): Observable<string> {
        return this.avatarGender.asObservable();
    }

    getAvatarConfig(gender: string): any {
        let avatarConfigRequest;

        if (gender === 'male') {
            avatarConfigRequest = { name: this.avatarConfig.maleName, voiceId: this.avatarConfig.maleVoiceId }
        }
        else if (gender === 'female') {
            avatarConfigRequest = { name: this.avatarConfig.femaleName, voiceId: this.avatarConfig.femaleVoiceId }
        }
        else {
            avatarConfigRequest = {
                name: '',
                voiceId: ''
            }
        }

        return avatarConfigRequest;
    }

    getAvatarConfigRequest(): Observable<AvatarConfigRequest> {
        return this.avatarConfigRequest;
    }

    getAvatarTalkOptions(): string[] {
        const defaultAvatarTalkOptions = [ "voice", "call" ]; // "text" for text messages, "voice" for voice and call messages

        /*if (this.config.avatarTalk.length > 0) {
           return this.config.avatarTalk
        } else {
            return defaultAvatarTalkOptions;
        }*/
        return defaultAvatarTalkOptions;
    }

    // setters
    setAgentId(id: string) {
        this.agentId = id;
    }

    setAgentConfig (config: AgentModel) {
        this.config = config;
        this.setSelectedLanguage('en-US');
    }

    setSelectedLanguage (locale: string) {
        this.config.languages = this.config.languages.map(language => {
            return {
                id: language.id,
                name: language.name,
                icon: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.icon ?? ""; })(),
                locale: language.locale,
                direction: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.direction ?? "ltr"; })(),
                selected: language.locale === locale,
                presenter_url: '', //language.presenter_url,
                order: language.order,
                voices: this.config.voices.map(voice => voice.languageLocale === language.locale ? voice : null)
            }
        })
    }

    setAvatarConfigRequest(config: AvatarConfigRequest): void {
        this.avatarConfigRequest.next(config);
    }

    setCharacterAssets() {
        const robotIcon = 'assets/icons/robot.svg';
        const maleAvatarIcon = 'assets/icons/silas.svg';
        const femaleAvatarIcon = 'assets/icons/june.svg';
        const maleAvatarImage = 'assets/images/silas.jpg';
        const femaleAvatarImage = 'assets/images/june.jpg';

        this.getCurrentAvatarGender().subscribe(gender => {
            if (this.getAvatarEnabled() && gender) {
                if (gender === 'male') {
                    this.characterIcon.next(maleAvatarIcon);
                    this.characterImage.next(maleAvatarImage);
                } else if (gender === 'female') {
                    this.characterIcon.next(femaleAvatarIcon);
                    this.characterImage.next(femaleAvatarImage);
                } else {
                    this.characterIcon.next(robotIcon);
                }
            } else {
                this.characterIcon.next(robotIcon);
            }
        })
    }

    setCurrentAvatarGender(gender: string) {
        return this.avatarGender.next(gender);
    }

    isMobileDevice(): boolean {
        // @ts-ignore
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

        // Check if the User-Agent matches common mobile device keywords
        const isMobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        // Check if the screen size is typical of mobile devices
        // Check if the device supports touch events
        //const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

        return window.innerWidth <= 992;
    }

    // methods
    async fetchConfiguration () {
        try {
            return await lastValueFrom(
                this.http
                .setHost(`${this.getHttpScheme()}://${this.getHost()}/api/agent/${this.getAgentId()}/get`)
                .setMethod("GET")
                .setHeaders({
                    "content-type": "application/json"
                })
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not get required configuration: ${e.toString()}`);
        }
    }
}
