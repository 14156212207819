<div class="document-analysis-dropdown-container">
    <button id="id-add-document" class="add-document-button" (click)="onAddAttachments()">
        <img [src]="addDocumentIcon" alt="Add Document Icon">
        <p>{{ addFileLabel }}</p>
    </button>
    <div *ngIf="hasUploadFiles()" class="documents-container" [ngClass]="{ 'expanded': isExpanded }">
        <div class="documents-wrapper" *ngFor="let file of displayedFiles; let i = index">
            <button id="id-button-document" type="button" class="document-button" (click)="chooseFile(file)" [class.selected]="isFileSelected(file)">
                <div class="pdf-icon">
                    <div class="document-icon">
                        <app-document></app-document>
                        <p class="document-extension"> {{ file.extension.toUpperCase() }}</p>
                    </div>
                    <button id="id-remove-file-analys" type="button" class="remove-file-button" (click)="removeFile(file, $event)">
                        <app-close></app-close>
                    </button>
                    <p class="document-name">{{ getDocumentName(file) }}</p>
                </div>
                <div *ngIf="isFileSelected(file)" class="active-circle"></div>
            </button>
        </div>
        <button *ngIf="getFilesLimit && !isExpanded" id="id-arrow-down" type="button" class="arrow-button down" (click)="expandDocumentList($event)">
            <app-arrow-down></app-arrow-down>
        </button>
        <button *ngIf="isExpanded" id="id-arrow-top" type="button" class="arrow-button top" (click)="collapseDocumentList($event)">
            <app-arrow-top></app-arrow-top>
        </button>
    </div>
</div>
<div class="file-selected-container">
    <input #fileInputDocument id="file" type="file" class="input-file-document" (change)="onFileSelect($event)" accept=".pdf"/>
</div>

